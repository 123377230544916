import React from 'react';
import { Box, Typography } from '@mui/material';
import { Attribute } from '../attributes';
import { useStyling } from '../../common/Theme';

type DecimalAttributePreviewProps = {
    attribute: Attribute;
};

export default (props: DecimalAttributePreviewProps) => {
    const { attribute } = props;
    const { theme } = useStyling();
    return (
        attribute && (
            <Box key={attribute.name} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography fontSize={'small'} color={theme.palette.text.disabled}>
                    {attribute.label}
                </Typography>
                <Typography fontSize={'small'}>{attribute.value ? Number.parseFloat(attribute.value).toFixed(attribute.decimalsNumber) : '-'}</Typography>
            </Box>
        )
    );
};
