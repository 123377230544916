import { ADMINISTRATION, MARKETPLACE, PLANNING, SUPPLIER, PROFILES } from '../common/nav/apps';
import { useContext, useMemo } from 'react';
import { UserContext } from '../common/auth/UserContext';

export default () => {
    const { application } = useContext(UserContext);

    const preFilterByApp = useMemo(() => {
        return {
            [ADMINISTRATION.id]: [{ identifier: 'public', value: 'false' }],
            [MARKETPLACE.id]: [{ identifier: 'public', value: 'true' }],
            [SUPPLIER.id]: [{ identifier: 'public', value: 'false' }],
            [PLANNING.id]: [{ identifier: 'public', value: 'false' }],
            [PROFILES.id]: [
                { identifier: 'public', value: 'false' },
                { identifier: 'owned', value: 'true' }
            ]
        };
    }, []);

    return preFilterByApp[application.id];
};
