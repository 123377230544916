import { find, isNil } from 'lodash';
import { useContext } from 'react';
import { UserContext } from '../auth/UserContext';
import { Membership, MembershipAccess } from '../auth/api';
import { APPLICATIONS, AppDefinition, PROFILES } from './apps';

export default (selectedOrgId?: string) => {
    const { userProfile } = useContext(UserContext);

    if (!isNil(userProfile)) {
        // user not sunscribed to any accounts
        if (userProfile.memberships?.length === 1 && userProfile.memberships[0].personal === true) {
            const url = `${PROFILES.home}?orgId=${userProfile.memberships[0].id}`;
            return { url: url, pathname: PROFILES.home, app: PROFILES, orgId: userProfile.memberships[0].id };
        } else {
            // Find membership by id or first non-personal one.
            const membership = find(userProfile.memberships, (m: Membership) => {
                if (selectedOrgId) {
                    return !m.personal && m.id === selectedOrgId;
                }
                // Return first membership that is not personal and has any access defined on it.
                return !m.personal && Array.isArray(m.access);
            });
            if (membership?.access?.length) {
                const preferredAppKey = 'preferredApp.' + userProfile.accountId;
                const preferedAppId = localStorage.getItem(preferredAppKey);
                if (preferedAppId) {
                    const preferredApp = find(APPLICATIONS, { id: preferedAppId });
                    if (preferredApp) {
                        const url = `${preferredApp.home}?orgId=${membership.id}`;
                        return { url: url, pathname: preferredApp.home, app: preferredApp, orgId: membership.id };
                    }
                }

                const first: MembershipAccess = membership.access[0];
                if (first) {
                    const found: AppDefinition = find(APPLICATIONS, { name: first.application });
                    if (found) {
                        const url = `${found.home}?orgId=${membership.id}`;
                        return { url: url, pathname: found.home, app: found, orgId: membership.id };
                    }
                }
            }
        }
    }
    return { url: null, pathname: null, app: null, orgId: null };
};
