import React from 'react';
import { Chip, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { Reference } from 'sr-types/lib/shortlist/v1/graphql';
import { H, V } from '../../common/Layout';
import I18n from '../../common/i18n/I18n';

export default ({ people }: { people: Reference[] }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleTooltipOpen = () => {
        setIsTooltipOpen(true);
    };

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    let displayPeople = people;

    if (people.length > 4) {
        displayPeople = people.slice(0, 4);
    }

    return (
        <H sx={{ gap: 1, flexWrap: 'wrap' }}>
            {displayPeople.map((person, index) => (
                <Chip label={person?.label} variant="outlined" key={person?.label} />
            ))}
            {people.length > 4 && (
                <Tooltip
                    title={
                        <V>
                            {people.map((person, index) => (
                                <Typography key={index}>{person.label}</Typography>
                            ))}
                        </V>
                    }
                    open={isTooltipOpen}
                    onClose={handleTooltipClose}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <Chip
                        label={
                            <I18n token="shortlist.lineItem.person.chip.more" values={{ value: people.length - 4 }} />
                        }
                        variant="outlined"
                        onMouseEnter={handleTooltipOpen}
                        onMouseLeave={handleTooltipClose}
                    />
                </Tooltip>
            )}
        </H>
    );
};
