import { Box, Typography } from '@mui/material';
import React from 'react';
import { useContext } from 'react';
import { DateRange, Location } from 'sr-types/lib/opportunity/v1/graphql';
import { UserContext } from '../../common/auth/UserContext';
import { FormContext } from '../../common/form/FormContext';
import I18n from '../../common/i18n/I18n';
import { Icons } from '../../common/icons/Icons';
import { H, V } from '../../common/Layout';
import { useStyling } from '../../common/Theme';
import { getStringFromDateRange } from '../../production/helpers/activityUtils';
import PeopleChipPreview from './PeopleChipPreview';

export default ({ productionType }: { productionType: string }) => {
    const { state } = useContext(FormContext);
    const { theme } = useStyling();
    const { userProfile, organizationAccountDetails } = useContext(UserContext);

    return (
        <V sx={{ p: 2, gap: 2 }}>
            <V sx={{ gap: 1 }}>
                <H sx={{ color: theme.palette.grey[700] }}>
                    <Icons.People fontSize={'medium'} />
                    <Typography fontSize={'medium'}>
                        <I18n token="shortlist.lineItem.person.invitee" />
                    </Typography>
                </H>
                <PeopleChipPreview people={state.people} />
            </V>
            <InquiryPreviewInfo
                productionName={state?.production?.name}
                orgName={organizationAccountDetails?.organizationName}
                role={state?.role}
                summary={state?.productionSummary}
                dates={state?.dateRange}
                locations={state?.locations}
                personalizedMessage={state?.personalizedMessage}
                productionType={productionType}
            />
            <Typography>
                <I18n token="form.shortlistLineItem.inquiry.respond1" />
                <Box component="span" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                    <I18n token="form.shortlistLineItem.inquiry.respond2" />
                </Box>
            </Typography>
            <V>
                <Typography>
                    <I18n token="shortlist.lineItem.person.thanks" />
                </Typography>
                <Typography>{userProfile.name.firstName}</Typography>
            </V>
        </V>
    );
};

export const InquiryPreviewInfo = ({
    personalizedMessage,
    productionName,
    orgName,
    role,
    summary,
    dates,
    locations,
    productionType
}: {
    personalizedMessage: string;
    productionName: string;
    orgName: string;
    role: string;
    summary: string;
    dates: DateRange;
    locations: Location[];
    productionType: string;
}) => {
    return (
        <>
            <Typography>{personalizedMessage}</Typography>
            <Typography>
                <I18n token="form.shortlistLineItem.inquiry.message1" />
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {orgName}
                </Box>
                <I18n token="form.shortlistLineItem.inquiry.message2" />
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {productionName} {productionType ? ` (${productionType})` : ''}
                </Box>
                <I18n token="form.shortlistLineItem.inquiry.message3" />
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {role}
                </Box>
                <I18n token="form.shortlistLineItem.inquiry.message4" />
            </Typography>

            <Typography>
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                    <I18n token="form.shortlistLineItem.inquiry.summary" />
                </Box>
                {summary || '-'}
            </Typography>
            <Typography>
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                    <I18n token="form.shortlistLineItem.inquiry.date" />
                </Box>
                {getStringFromDateRange(dates) || '-'}
            </Typography>
            {locations && Array.isArray(locations) ? (
                <Typography>
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                        <I18n token="form.shortlistLineItem.inquiry.locations" />
                    </Box>
                    {
                        <>
                            {locations.map((location, index) => (
                                <React.Fragment key={location?.label}>
                                    {location?.label}
                                    {index !== locations?.length - 1 && ' | '}
                                </React.Fragment>
                            ))}
                        </>
                    }
                </Typography>
            ) : (
                <></>
            )}
        </>
    );
};
