import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../auth/UserContext';
import { getToken } from '../auth/api';
import { DOWNLOAD_URL } from '../utils/download';

const free = (objectUrl: string) => {
    // console.log('Freeing:', objectUrl);
    URL.revokeObjectURL(objectUrl);
};

export default (attachmentId: string) => {
    const [attachment, setAttachment] = useState<string>('');
    const { activeOrganizationAccount } = useContext(UserContext);

    useEffect(() => {
        if (!isEmpty(attachmentId)) {
            const token = getToken();
            const headers = new Headers();
            headers.append('Authorization', token ? 'Bearer ' + token.token : '');
            headers.append('ownerId', activeOrganizationAccount);
            const imgUrl = `${DOWNLOAD_URL}/${attachmentId}`;
            let objectUrl: string;
            fetch(imgUrl, { headers }).then((response) => {
                if (response.ok) {
                    response.blob().then((value) => {
                        objectUrl = URL.createObjectURL(value);
                        // console.log('Attachment', attachmentId, '=>', objectUrl);
                        setAttachment(objectUrl);
                    });
                }
            });
            return () => {
                URL.revokeObjectURL(objectUrl);
            };
        }
    }, [attachmentId]);

    return { attachment, free: () => URL.revokeObjectURL(attachment) };
};
