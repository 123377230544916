import React from 'react';
import I18n from '../../common/i18n/I18n';
import useHistory from '../utils/useHistory';
import { Box, Link, Typography } from '@mui/material';
import { Icons } from '../icons/Icons';

export const authLinks = {
    login: {
        path: '/login',
        label: 'Log in',
        i18n: 'account.login',
        icon: 'lockOpen'
    },
    logout: {
        path: '/',
        label: 'Log out',
        i18n: 'account.logout',
        icon: 'exit'
    },
    signup: {
        path: '/signup',
        label: 'Sign up',
        i18n: 'account.signup',
        icon: 'user'
    },
    verify: {
        path: '/verify',
        label: 'Verify account',
        i18n: 'account.verify',
        icon: 'pencil'
    },
    passwordRecovery: {
        path: '/password/recovery',
        label: 'Password recovery',
        i18n: 'account.verify',
        icon: 'pencil'
    },
    confirmPassword: {
        path: 'confirm',
        label: 'Confirm password',
        i18n: 'account.verify',
        icon: 'pencil'
    },
    authentication: {
        path: '/password/recovery/authentication',
        label: 'Authentication',
        i18n: 'account.verify',
        icon: 'pencil'
    }
};

export function NeedAccount() {
    const { changeRoute } = useHistory();

    return (
        <Typography fontSize={'small'}>
            <I18n token="account.required.question" />{' '}
            <Link
                className="clickable"
                onClick={() => {
                    changeRoute(authLinks.signup.path);
                }}
            >
                <I18n token={authLinks.signup.i18n} />
            </Link>
        </Typography>
    );
}

export function HaveAccount() {
    const { changeRoute } = useHistory();

    return (
        <Typography fontSize={'small'}>
            <I18n token="account.exists.question" />{' '}
            <Link
                className="clickable"
                onClick={() => {
                    changeRoute(authLinks.login.path);
                }}
            >
                <I18n token={authLinks.login.i18n} />
            </Link>
        </Typography>
    );
}

export function AuthFormHeader({ title }) {
    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
            <Icons.Lock color={'primary'} fontSize={'large'} />
            <Typography sx={{ fontSize: 'x-large', fontWeight: 'bold' }}>{title}</Typography>
        </Box>
    );
}

export function ForgotPassword({ username }) {
    const { changeRoute } = useHistory();
    return (
        <Typography fontSize={'small'}>
            <Link
                className="clickable"
                onClick={() => {
                    changeRoute(authLinks.passwordRecovery.path, { username: username }, true);
                }}
            >
                <I18n token="account.forgot.password" />
            </Link>
        </Typography>
    );
}
