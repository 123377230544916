import { GridColDef } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import papa from 'papaparse';
import React, { useContext, useEffect, useState } from 'react';
import { rowsToContacts } from '../../supply/opportunity/details/ContactListUtils';
import { V } from '../Layout';
import { useStyling } from '../Theme';
import { UserContext } from '../auth/UserContext';
import Loading from '../components/Loading';
import FormModal from '../form/FormModal';
import { Icons } from '../icons/Icons';
import { generateTempId } from '../utils/commonUtils';
import SearchLocation from '../../supply/product/SearchLocation';
import { EnvironmentPermissionContext } from '../auth/EnvironmentPermissionContext';
import I18n from '../i18n/I18n';
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import PreviewSection from './SRDataImportComponents/PreviewSection';
import FileUploadSection from './SRDataImportComponents/FileUploadSection';
import SelectionOrFinalPreviewSection from './SRDataImportComponents/SelectionAndReviewSection';

export default ({
    feature,
    onSave,
    onClose,
    isUpload,
    primaryColDefs,
    altColDefLabel = undefined,
    altColDefs,
    columnVisibility = {},
    rowsToEntities,
    doSave,
    locationSearch = false,
    bulkSave,
    placeholder = '',
    title = 'Add',
    saveLabel = 'Update',
    editLabel = 'Edit',
    setImportDetails = undefined,
    setShowImportDetailsDialog = undefined,
    disableTextBasedSelector = false,
    disableBulkSave = false,
    validateImportedData = undefined,
    existingData = [],
    apiData = undefined,
    hasDataToImport = false,
    dataSource = undefined,
    onReset = undefined,
    isDataSourceOption = false,
    category = undefined
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(isUpload);
    const [hasHeaders, setHasHeaders] = useState(false);
    const [isAltColDef, setIsAltColDef] = useState(false);
    const [text, setText] = useState(apiData || '');
    const [defaultColumns, setDefaultColumns] = useState<GridColDef[]>(primaryColDefs);
    const [rows, setRows] = useState([]);
    const [searchLocations, setSearchLocations] = useState([]);
    const [errors, setErrors] = useState(undefined);
    const { activeOrganizationAccount, getPreference } = useContext(UserContext);
    const [isDataImported, setIsDataImported] = useState(false);
    const [hasHeadersInTable, setHasHeadersInTable] = useState(false);
    const { theme } = useStyling();
    const [invalidData, setInvalidData] = useState(false);
    const columnPreferences = getPreference('data-import-grid', 'my-columns');

    const { allowWIP } = useContext(EnvironmentPermissionContext);
    const [isReviewAndEdit, setIsReviewAndEdit] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [pageSize, setPageSize] = useState(24);
    const [page, setPage] = useState(0);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(undefined);
    const [isSelectScreenActive, setIsSelectScreenActive] = useState(false);
    const [isCsvUploadScreenActive, setIsCsvUploadScreenActive] = useState(!hasDataToImport);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

    useEffect(() => {
        setDefaultColumns(isAltColDef ? altColDefs : primaryColDefs);
    }, [isAltColDef]);

    const showColumnsBasedOnPreference = (defaultColumns: GridColDef[]) => {
        return defaultColumns.filter((col) => columnPreferences[col?.field] !== false);
    };

    const createPreview = (hasHeadersInTable = false) => {
        setRows([]);
        if (text) {
            const cleanedInput = text.replace(/,\s+"/g, ',"');
            const parsed = papa.parse(cleanedInput, {
                skipEmptyLines: 'greedy'
            });
            let lines = [...parsed.data];
            if (hasHeadersInTable) {
                lines.shift();
            } else {
                lines = [...parsed.data];
            }
            if (hasHeaders) {
                lines.shift();
            }

            const r = lines.map((line) => {
                const index = 'IG_' + generateTempId();
                const mapValueToColumns = (columns: GridColDef[]) => {
                    return columns.forEach((c: GridColDef, index: number) => {
                        row[c.field] = line[index]?.trim();
                    });
                };

                const row = { id: index };
                mapValueToColumns(
                    hasDataToImport
                        ? defaultColumns
                        : columnPreferences
                          ? showColumnsBasedOnPreference(defaultColumns)
                          : defaultColumns
                );
                return row;
            });
            if (r.length) {
                setRows(r);
            }
        }
    };

    useEffect(() => {
        if (validateImportedData) {
            validateImportedData(rows, existingData, setInvalidData, setErrors);
        }
        if (locationSearch && rows.length) {
            const locationS = rows.filter((row) => {
                return !isEmpty(row.location);
            });
            setSearchLocations(locationS);
        }
        if (isSelectScreenActive) {
            setSelectionModel(() => rows.map((r) => r.id));
            setSelectedRows(rows);
        }
    }, [rows]);

    const handleSelectionChange = (ids) => {
        setSelectionModel(ids);
        const selectedIDs = new Set(ids);
        const selectedRows = rows.filter((r) => selectedIDs.has(r.id));
        setSelectedRows(selectedRows);
    };

    const handleDelete = (id) => {
        setSelectedRows(selectedRows.filter((row) => row.id !== id));
    };

    const localReadFile = (file) => {
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = function (evt) {
            if (evt.target.readyState === evt.target.DONE) {
                console.log('onload:', evt);
                // @ts-ignore
                setText(evt.target.result);
            }
        };
        reader.onerror = function (evt) {
            console.log('error reading file:', evt);
        };
    };

    const editAction = {
        label: editLabel,
        onClick: () => {
            setErrors(undefined);
            setIsPreview(false);
            setInvalidData(false);
        }
    };
    const uploadAction = {
        label: 'Import',
        Icon: Icons.FileUpload,
        onClick: () => {
            setOpenUploadDialog(true);
            //Import process starts again
            if (isDataImported) {
                setIsPreview(false);
                setIsDataImported(false);
                setHasHeadersInTable(false);
                setText('');
            }
        }
    };

    const navigateBackAction = {
        label: <I18n token="import.contacts.uploadfile.modal.back" />,
        onClick: () => {
            if (isReviewAndEdit) {
                setIsReviewAndEdit(false);
                setIsSelectScreenActive(true);
            } else if (isSelectScreenActive) {
                if (hasDataToImport) {
                    setIsSelectScreenActive(false);
                    onReset();
                } else {
                    setSelectionModel([]);
                    setIsSelectScreenActive(false);
                    setIsCsvUploadScreenActive(true);
                }
            } else {
                setSelectionModel([]);
                setSelectedRows([]);
                setIsCsvUploadScreenActive(false);
                onReset();
            }
        }
    };

    useEffect(() => {
        if (isDataImported) {
            createPreview();
            setIsPreview(true);
        }
    }, [isDataImported]);

    const EmptyNoRowsOverlay = () => {
        return <span></span>;
    };

    useEffect(() => {
        if (hasDataToImport) {
            setOpenUploadDialog(false);
            createPreview();
            setIsSelectScreenActive(true);
        }
    }, [hasDataToImport]);

    const handleClose = () => {
        setSelectedRows([]);
        setIsReviewAndEdit(false);
        setIsSelectScreenActive(false);
        setIsCsvUploadScreenActive(false);
        onClose();
    };

    return !allowWIP ? (
        <FormModal
            id={'import-dialog'}
            width={'80%'}
            title={title}
            isOpen={true}
            expanded={true}
            onClose={onClose}
            isLoading={false}
            saveLabel={isPreview ? saveLabel : 'Preview'}
            isSaveDisabled={isSaving || invalidData}
            onSave={() => {
                if (isPreview) {
                    if (disableBulkSave) {
                        onClose();
                        const contacts = rowsToContacts(rows, isAltColDef ? altColDefs : primaryColDefs);
                        onSave(contacts);
                    } else {
                        doSave(
                            setIsSaving,
                            rows,
                            isAltColDef,
                            altColDefs,
                            primaryColDefs,
                            defaultColumns,
                            setErrors,
                            onClose,
                            onSave,
                            bulkSave,
                            activeOrganizationAccount,
                            setImportDetails,
                            setShowImportDetailsDialog
                        );
                    }
                } else {
                    createPreview();
                    setOpenUploadDialog(false);
                    setIsPreview(true);
                }
            }}
            isSaving={false}
            additionalActions={isPreview && !isDataImported ? [editAction] : hasDataToImport ? [] : [uploadAction]}
        >
            <V sx={{ height: '100%', pl: 2, pr: 2, gap: 1 }}>
                {isSaving ? (
                    <Loading />
                ) : isPreview ? (
                    <PreviewSection
                        feature={feature}
                        rows={rows}
                        defaultColumns={defaultColumns}
                        columnPreferences={columnPreferences}
                        showColumnsBasedOnPreference={showColumnsBasedOnPreference}
                        columnVisibility={columnVisibility}
                        errors={errors}
                        theme={theme}
                        disableTextBasedSelector={disableTextBasedSelector}
                        hasHeadersInTable={hasHeadersInTable}
                        createPreview={createPreview}
                        setHasHeadersInTable={setHasHeadersInTable}
                    />
                ) : (
                    <FileUploadSection
                        EmptyNoRowsOverlay={EmptyNoRowsOverlay}
                        feature={feature}
                        defaultColumns={defaultColumns}
                        setDefaultColumns={setDefaultColumns}
                        columnVisibility={columnVisibility}
                        text={text}
                        setText={setText}
                        placeholder={placeholder}
                        activeOrganizationAccount={activeOrganizationAccount}
                        openUploadDialog={openUploadDialog}
                        setOpenUploadDialog={setOpenUploadDialog}
                        setIsDataImported={setIsDataImported}
                        disableTextBasedSelector={disableTextBasedSelector}
                        hasDataToImport={hasDataToImport}
                        hasHeaders={hasHeaders}
                        setHasHeaders={setHasHeaders}
                        altColDefLabel={altColDefLabel}
                        isAltColDef={isAltColDef}
                        setIsAltColDef={setIsAltColDef}
                    />
                )}
            </V>
            {searchLocations.length === 0 ? (
                <></>
            ) : (
                searchLocations.map((row, index) => {
                    return <SearchLocation key={index} row={row} />;
                })
            )}
        </FormModal>
    ) : (
        <>
            <FormModal
                id={'import-dialog'}
                width={'80%'}
                title={title}
                isOpen={true}
                expanded={true}
                onClose={handleClose}
                isLoading={false}
                saveLabel={isReviewAndEdit ? saveLabel : <I18n token="person.import.contact.button.continue" />}
                isSaveDisabled={
                    isSaving || invalidData || text.length == 0 || (isReviewAndEdit && selectedRows.length == 0)
                }
                onSave={() => {
                    if (isReviewAndEdit) {
                        if (disableBulkSave) {
                            onClose();
                            const contacts = rowsToContacts(selectedRows, isAltColDef ? altColDefs : primaryColDefs);
                            onSave(contacts);
                        } else {
                            doSave(
                                setIsSaving,
                                selectedRows,
                                isAltColDef,
                                altColDefs,
                                primaryColDefs,
                                defaultColumns,
                                setErrors,
                                onClose,
                                onSave,
                                bulkSave,
                                activeOrganizationAccount,
                                setImportDetails,
                                setShowImportDetailsDialog
                            );
                        }
                    } else if (isSelectScreenActive) {
                        setIsSelectScreenActive(false);
                        setIsReviewAndEdit(true);
                    } else {
                        setOpenUploadDialog(false);
                        setIsCsvUploadScreenActive(false);
                        createPreview();
                        setIsSelectScreenActive(true);
                    }
                }}
                isSaving={false}
                additonalLeftsideActions={
                    isDataSourceOption ? [navigateBackAction] : isCsvUploadScreenActive ? [] : [navigateBackAction]
                }
                additionalActions={!isReviewAndEdit && !isSelectScreenActive && !isDataImported ? [uploadAction] : []}
            >
                <V sx={{ height: '100%', px: 2, gap: 1 }}>
                    {isSaving ? (
                        <Loading />
                    ) : (
                        <>
                            {isCsvUploadScreenActive && (
                                <FileUploadSection
                                    EmptyNoRowsOverlay={EmptyNoRowsOverlay}
                                    feature={feature}
                                    defaultColumns={defaultColumns}
                                    setDefaultColumns={setDefaultColumns}
                                    columnVisibility={columnVisibility}
                                    text={text}
                                    setText={setText}
                                    placeholder={placeholder}
                                    activeOrganizationAccount={activeOrganizationAccount}
                                    openUploadDialog={openUploadDialog}
                                    setOpenUploadDialog={setOpenUploadDialog}
                                    setIsDataImported={setIsDataImported}
                                    disableTextBasedSelector={disableTextBasedSelector}
                                    hasDataToImport={hasDataToImport}
                                    hasHeaders={hasHeaders}
                                    setHasHeaders={setHasHeaders}
                                    altColDefLabel={altColDefLabel}
                                    isAltColDef={isAltColDef}
                                    setIsAltColDef={setIsAltColDef}
                                />
                            )}
                            {(isSelectScreenActive || isReviewAndEdit) && (
                                <SelectionOrFinalPreviewSection
                                    feature={feature}
                                    isReviewAndEdit={isReviewAndEdit}
                                    category={category}
                                    dataSource={dataSource}
                                    selectedRows={selectedRows}
                                    rows={rows}
                                    defaultColumns={defaultColumns}
                                    columnPreferences={columnPreferences}
                                    showColumnsBasedOnPreference={showColumnsBasedOnPreference}
                                    selectionModel={selectionModel}
                                    handleSelectionChange={handleSelectionChange}
                                    handleDelete={handleDelete}
                                    page={page}
                                    paginationModel={paginationModel}
                                    setPaginationModel={setPaginationModel}
                                    setPage={setPage}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    setSelectedRows={setSelectedRows}
                                    hasDataToImport={hasDataToImport}
                                />
                            )}
                        </>
                    )}
                </V>
            </FormModal>
        </>
    );
};
