import { stringToColor } from '../Theme';
import { Filter } from '@searchkit/client';
import { enqueueSnackbar } from '../components/Toast';
import I18n from '../i18n/I18n';
import React from 'react';

export function stringAvatar(name: string) {
    if (name) {
        const arr = name.split(' ');
        return {
            sx: {
                bgcolor: stringToColor(name)
            },
            children:
                arr.length > 1 ? `${arr[0][0]}${arr[1][0]}` : `${arr[0][0].toUpperCase()}${arr[0][1].toUpperCase()}`
        };
    }
    return {};
}

export const emailObfuscation = (email: string) => {
    const splitEmail = email.split('@');
    const domain = splitEmail[1];
    const name = splitEmail[0];
    const lastTwoLetters = name.slice(-2);
    return name.substring(0, 2).concat('*****').concat(lastTwoLetters).concat('@').concat(domain);
};

export const log = (...args) => {
    if (window.DEBUG) {
        console.log(...args);
    }
};

export const generateTempId = () => {
    const tempId = Math.round(Math.random() * Number.MAX_SAFE_INTEGER);
    return tempId;
};

export function getNestedFieldValue(obj, field) {
    if (obj && typeof obj === 'object') {
        if (obj.hasOwnProperty(field)) {
            return obj[field];
        }
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const nestedValue = getNestedFieldValue(obj[key], field);
                if (nestedValue !== undefined) {
                    return nestedValue;
                }
            }
        }
    }
    return undefined;
}

export const successToastMessage = (token: string, values = {}) => {
    return enqueueSnackbar(<I18n token={token} values={values} />, { variant: 'success' });
};

export const failedToastMessage = (token: string, values = {}) => {
    return enqueueSnackbar(<I18n token={token} values={values} />, { variant: 'error' });
};

/**
 * Checks if any of the specified filters are present in the target list of filters.
 *
 * @param targetFilters - The list of filters to search in.
 * @param filtersToCheck - The filters to look for.
 *
 * @returns `true` if any of the filters in `filtersToCheck` are found in `targetFilters`, otherwise `false`.
 */
export const isAnyFilterInList = (targetFilters: Filter[], filtersToCheck: Filter[]) => {
    for (const filterToCheck of filtersToCheck) {
        const foundFilter = targetFilters.find(
            (filter) => filter.identifier === filterToCheck.identifier && filter.value === filterToCheck.value
        );
        if (foundFilter) {
            return true;
        }
    }
    return false;
};

/**
 * Checks if all of the specified filters are present in the target list of filters.
 *
 * @param targetFilters - The list of filters to search in.
 * @param filtersToCheck - The filters to look for.
 *
 * @returns `true` if all filters in `filtersToCheck` are found in `targetFilters`, otherwise `false`.
 */
export const areAllFiltersInList = (targetFilters: Filter[], filtersToCheck: Filter[]) => {
    for (const filterToCheck of filtersToCheck) {
        const foundFilter = targetFilters.find(
            (filter) => filter.identifier === filterToCheck.identifier && filter.value === filterToCheck.value
        );
        if (!foundFilter) {
            return false;
        }
    }
    return true;
};

export const hyperlinkSx = {
    '&:hover': {
        textDecoration: 'underline',
        color: 'primary.main',
        cursor: 'pointer'
    }
};
