import React from 'react';
import { Box, Typography } from '@mui/material';
import { Attribute } from './../attributes';
import { useStyling } from '../../common/Theme';
import I18n from '../../common/i18n/I18n';

type BooleanAttributePreviewProps = {
    attribute: Attribute;
};

export default (props: BooleanAttributePreviewProps) => {
    const { attribute } = props;
    const { theme } = useStyling();
    return (
        attribute && (
            <Box key={attribute.name} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography fontSize={'small'} color={theme.palette.text.disabled}>
                    {attribute.label}
                </Typography>
                <Typography fontSize={'small'}>{attribute.value ? <I18n token="attributes.boolean.true" /> : <I18n token="attributes.boolean.false" />}</Typography>
            </Box>
        )
    );
};
