import { Box, Chip, IconButton, ListItemIcon, ListItemText, MenuItem, SxProps, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import React, { useState } from 'react';
import { H } from '../Layout';
import { useStyling } from '../Theme';
import I18n from '../i18n/I18n';
import { Icons } from '../icons/Icons';
import { ActionType } from './ActionType';

const MobileMenu = ({ actions, mobileMoreAnchorEl, isMobileMenuOpen, handleMobileMenuClose, iconSx }) => {
    const { theme } = useStyling();

    return (
        <Menu
            id={'actions-menu-mobile'}
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {actions.map((action: ActionType, index: number) => (
                <MenuItem
                    key={index}
                    disabled={action.disabled}
                    onClick={action.disabled ? undefined : action.onClick}
                    sx={{
                        '.MuiListItemIcon-root': {
                            minWidth: 'fit-content'
                        },
                        px: 1.5,
                        py: 0
                    }}
                >
                    <ListItemIcon>
                        {action.icon ? (
                            <action.icon
                                fontSize={'small'}
                                sx={{
                                    color: action.disabled ? theme.palette.text.disabled : theme.palette.text.primary,
                                    ...iconSx
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </ListItemIcon>
                    <ListItemText>{action.label}</ListItemText>
                </MenuItem>
            ))}
        </Menu>
    );
};

const DesktopContent = ({
    actions,
    showBorder,
    containerSx,
    iconSx
}: {
    actions: ActionType[];
    showBorder: boolean;
    containerSx: SxProps;
    iconSx?: SxProps;
}) => {
    const { theme, isMobile } = useStyling();

    return showBorder ? (
        <Box
            component="div"
            sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                right: '5px',
                top: '5px',
                px: isMobile ? 0 : 1,
                py: 0,
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                color: theme.palette.text.primary,
                borderRadius: '18px',
                height: '42px',
                ...containerSx
            }}
        >
            <H sx={{ width: 'unset' }}>
                {actions.map((action: ActionType, index: number) => (
                    <IconButton
                        key={index}
                        disabled={action.disabled}
                        size={'small'}
                        onClick={action.disabled ? undefined : action.onClick}
                    >
                        {action.label && typeof action.label !== 'string' ? (
                            <Chip className="clickable" size="small" label={action.label} variant={'outlined'} />
                        ) : action.icon ? (
                            <action.icon
                                fontSize={'small'}
                                sx={{
                                    color: action.disabled ? theme.palette.text.disabled : theme.palette.text.primary,
                                    ...iconSx
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </IconButton>
                ))}
            </H>
        </Box>
    ) : (
        <H
            sx={{
                width: 'unset',
                backgroundColor: theme.palette.background.paper,
                borderRadius: '18px',
                ...containerSx
            }}
        >
            {actions.map((action: ActionType, index: number) => (
                <Tooltip key={index} title={action.label}>
                    <IconButton disabled={action.disabled} size={'small'} component="span" onClick={action.onClick}>
                        {action.icon ? (
                            <action.icon
                                fontSize={'small'}
                                sx={{
                                    color: action.disabled ? theme.palette.text.disabled : theme.palette.text.primary,
                                    ...iconSx
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </IconButton>
                </Tooltip>
            ))}
        </H>
    );
};

type ResponsiveActionsProps = {
    isActivated: boolean;
    actions: ActionType[];
    showBorder?: boolean;
    containerSx?: SxProps;
    iconSx?: SxProps;
};

export default (props: ResponsiveActionsProps) => {
    const { isActivated, showBorder = false, actions, containerSx, iconSx } = props;
    const { theme, isMobile } = useStyling();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMobileMenuClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setIsMobileMenuOpen(false);
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMobileMoreAnchorEl(event.currentTarget);
        setIsMobileMenuOpen(true);
    };
    return (
        <React.Fragment>
            {isMobile ? (
                <>
                    <Tooltip title={<I18n token={'menu.responsive.showMore'} />}>
                        <IconButton
                            sx={{
                                ...containerSx,
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '18px'
                            }}
                            size="large"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <Icons.MoreVert />
                        </IconButton>
                    </Tooltip>
                    <MobileMenu
                        actions={actions}
                        handleMobileMenuClose={handleMobileMenuClose}
                        isMobileMenuOpen={isMobileMenuOpen}
                        mobileMoreAnchorEl={mobileMoreAnchorEl}
                        iconSx={iconSx}
                    />
                </>
            ) : isActivated ? (
                <DesktopContent actions={actions} containerSx={containerSx} showBorder={showBorder} iconSx={iconSx} />
            ) : (
                <React.Fragment />
            )}
        </React.Fragment>
    );
};
