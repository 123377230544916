import React from 'react';
import { Box, Typography } from '@mui/material';
import { Attribute } from '../attributes';
import { useStyling } from '../../common/Theme';

type TermsetAttributePreviewProps = {
    attribute: Attribute;
};

export default (props: TermsetAttributePreviewProps) => {
    const { attribute } = props;
    const { theme } = useStyling();
    return (
        attribute && (
            <Box key={attribute.name} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography fontSize={'small'} color={theme.palette.text.disabled}>
                    {attribute.label}
                </Typography>
                <Typography fontSize={'small'}>
                    {attribute.value ? (
                        <Box component="span">
                            {Array.isArray(attribute.value) && attribute.value.length
                                ? attribute.value.map((val, index) => (index < attribute.value.length - 1 ? val + ', ' : val))
                                : attribute.value}
                        </Box>
                    ) : (
                        '-'
                    )}
                </Typography>
            </Box>
        )
    );
};
