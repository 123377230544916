import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache } from '@apollo/client';
import { FRAGMENTS, ReferenceFragment } from './fragments';
import { ADDITIONAL_FRAGMENTS } from './additionalFragments';
import { authLink } from '../auth/api';

const raw = require('./possibleTypes.json').default;

const possibleTypes = {};
raw.data.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
        possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name);
    }
});

export const searchClient = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/search/v1/'
        })
    ),
    cache: new InMemoryCache({
        possibleTypes: possibleTypes,
        addTypename: false
    })
});

export const slimSearchQuery = (entity = undefined, includeAllActions = false, additionalFragmentName = undefined): DocumentNode => {
    const hasEntity = entity && FRAGMENTS[entity];
    const hasAdditionalFragment = additionalFragmentName && ADDITIONAL_FRAGMENTS[additionalFragmentName]
    return gql`
            query slimSearchQuery($entity: String, $query: String, $filter: Filter, $page: SKPageInput, $sortBy: String, $tokenize: Boolean) {
                search(entity: $entity, query: $query, filter: $filter, page: $page, sortBy: $sortBy, tokenize: $tokenize, includeAllActions: ${includeAllActions}) {
                    hits(page: $page, sortBy: $sortBy) {
                        items {
                            id
                            name
                            uri
                            headline
                            about
                            reference {
                                ...Reference
                            }
                            ${hasEntity ? `...${entity}` : ''}
                            ${hasAdditionalFragment ? `...${additionalFragmentName}` : ''}
                        }
                        page {
                            total
                            totalPages
                            pageNumber
                            from
                            size
                        }
                        sortedBy
                    }
                }
            }
            ${ReferenceFragment}
            ${hasEntity ? FRAGMENTS[entity] : ''}
            ${hasAdditionalFragment ? ADDITIONAL_FRAGMENTS[additionalFragmentName] : ''}
        `;
};

export const slimResultsQuery = (entity = undefined, includeAllActions = false): DocumentNode => {
    const hasEntity = entity && FRAGMENTS[entity];
    return gql`
            query slimResultsQuery($query: String, $filters: [SKFiltersSet], $page: SKPageInput, $sortBy: String, $tokenize: Boolean) {
                results(query: $query, filters: $filters, page: $page, sortBy: $sortBy, tokenize: $tokenize, includeAllActions: ${includeAllActions}) {
                    summary {
                        total
                        query
                        appliedFilters {
                            id
                            identifier
                            display
                            label
                            ... on DateRangeSelectedFilter {
                                dateMin
                                dateMax
                                __typename
                            }
                            ... on NumericRangeSelectedFilter {
                                min
                                max
                                __typename
                            }
                            ... on ValueSelectedFilter {
                                value
                                __typename
                            }
                        }
                        sortOptions {
                            id
                            label
                        }
                    }
                    hits(page: $page, sortBy: $sortBy) {
                        items {
                            id
                            name
                            uri
                            headline
                            about
                            reference {
                                ...Reference
                            }
                            ${hasEntity ? `...${entity}` : ''}
                        }
                        page {
                            total
                            totalPages
                            pageNumber
                            from
                            size
                        }
                        sortedBy
                    }
                    facets(tokenize: $tokenize) {
                        identifier
                        type
                        label
                        display
                        entries {
                            id
                            label
                            count
                            __typename
                        }
                        __typename
                    }
                }
            }
            ${ReferenceFragment}
            ${hasEntity ? FRAGMENTS[entity] : ''}
        `;
};
