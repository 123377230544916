import React from 'react';
import DataGrid from '../../list/DataGrid';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import I18n from '../../i18n/I18n';
import { isEmpty } from 'lodash';
import { V } from '../../Layout';

export default ({
    feature,
    rows,
    defaultColumns,
    columnPreferences,
    showColumnsBasedOnPreference,
    columnVisibility,
    errors,
    theme,
    disableTextBasedSelector,
    hasHeadersInTable,
    createPreview,
    setHasHeadersInTable
}) => (
    <V sx={{ height: '100%' }}>
        <DataGrid
            id={'data-import-preview'}
            containerSx={{
                width: '100%',
                '& .cellInvalid': {
                    backgroundColor: theme.palette.error.light,
                    fontWeight: 'bold'
                }
            }}
            feature={feature}
            rowCount={rows.length}
            rows={rows}
            columns={columnPreferences ? showColumnsBasedOnPreference(defaultColumns) : defaultColumns}
            defaultColumnVisibility={columnVisibility}
            disableColumnMenu
            disablePagination
            serverPagination={false}
            includeActions={false}
            errors={errors}
        />
        {disableTextBasedSelector && (
            <FormControlLabel
                sx={{ flexGrow: 0, ml: 0 }}
                control={<Switch size={'small'} />}
                label={<I18n token="import.contacts.uploadfile.hasheaders" />}
                checked={hasHeadersInTable}
                onChange={(event, newValue) => {
                    createPreview(newValue);
                    setHasHeadersInTable(newValue);
                }}
            />
        )}
        {isEmpty(errors) ? null : (
            <Typography
                sx={{
                    mt: 1,
                    px: 0.5,
                    backgroundColor: theme.palette.error.light
                }}
            >
                <I18n token="import.contacts.markedfield" />
            </Typography>
        )}
    </V>
);
