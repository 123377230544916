import { useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { InquiryResponseInput } from 'sr-types/lib/opportunity/v1/graphql';
import { UserContext } from '../../common/auth/UserContext';
import { constants } from '../../common/constants';
import { useI18n } from '../../common/i18n/I18n';
import { searchClient, slimSearchQuery } from '../../common/list/slimQuery';
import { failedToastMessage, successToastMessage } from '../../common/utils/commonUtils';
import { client, clientWithoutAuth, inquiryStatusDetails, saveInquiryResponse } from './opportunity';

export const VENDOR_KEY = 'vendor';
export const SPACE_REGEX = /\s+/g;

export const VENDOR_RESPONSE_ID = 'org-response-paragraph';
export const CREW_RESPONSE_ID = 'crew-response-paragraph';

export const inquiryResponseStatuses = {
    yes: 'Available',
    no: 'Not Available',
    requestInfo: 'Information Requested',
    requestSent: 'Request Sent'
};

export const getCurrentResponseFromStatus = (status: string) => {
    return Object.entries(inquiryResponseStatuses).find(([_key, val]) => val === status);
};

export const useGetResponseNoteLabels = () => {
    const yesNoteLabel = useI18n('form.textArea.yes');
    const noNoteLabel = useI18n('form.textArea.no');
    const requestInfoNoteLabel = useI18n('form.textArea.request');
    const noteLabels = {
        yes: yesNoteLabel,
        no: noNoteLabel,
        requestInfo: requestInfoNoteLabel
    };
    return { noteLabels };
};

export const useSaveInquiryResponse = (token: string) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    let queryDetail = {};
    if (token) {
        queryDetail = {
            client: clientWithoutAuth,
            context: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        };
    } else {
        queryDetail = {
            client,
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        };
    }
    const [save, { loading: isSaving }] = useMutation(saveInquiryResponse, {
        ...queryDetail
    });

    const saveOpportunityInquiryResponse = (inquiryResponse: InquiryResponseInput) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    inquiryResponse
                }
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    return { saveOpportunityInquiryResponse, isSaving };
};

export const handleInquiryResponse = (res: any, name: string) => {
    if (!res.data.saveInquiryResponse.errors) {
        successToastMessage('form.inquiry.response.success.message');
    } else {
        failedToastMessage('form.shortlistLineItem.inquiry.error');
    }
};

export const createSaveInquiryResponse = (inquiryId: string, state) => {
    const inquiryResponseObject: InquiryResponseInput = {
        inquiryId,
        proposedDate: null,
        requestNote: state?.requestNote,
        status: state?.status?.id
    };
    return inquiryResponseObject;
};

export const useGetProductData = (productEntityFilters, skip: boolean) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const { data: productData } = useQuery(slimSearchQuery('Product'), {
        client: searchClient,
        variables: {
            entity: 'Product',
            tokenize: true,
            sortBy: undefined,
            filter: productEntityFilters,
            page: {
                from: 0,
                size: 1000
            }
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        skip
    });

    return { productData };
};

export const useGetInquiryStatusDetails = (id: string, token: string) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    let queryDetail = {};
    if (token) {
        queryDetail = {
            client: clientWithoutAuth,
            notifyOnNetworkStatusChange: true,
            context: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        };
    } else {
        queryDetail = {
            client,
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        };
    }
    const { data: inquiryStatusData } = useQuery(inquiryStatusDetails, {
        variables: { id },
        fetchPolicy: constants.apolloFetchPolicy,
        ...queryDetail
    });
    return { inquiryStatusData };
};

export const createProductEntityFilters = (extractedCombinations) => {
    if (extractedCombinations.length > 0) {
        return {
            shouldFilters: extractedCombinations.map((combination) => ({
                mustFilters: [
                    {
                        expressions: [
                            { field: 'categories', value: { values: [combination?.category] } },
                            { field: 'locality', value: { values: [combination?.locality] } },
                            { field: 'countries', value: { values: [combination?.countryRegion] } }
                        ]
                    }
                ]
            }))
        };
    } else {
        return {
            mustFilters: []
        };
    }
};

export const extractStatusFromResponse = (responseText: string) => {
    return inquiryResponseStatuses[responseText];
};

export const extractCombinations = (resourceData = []) => {
    const filterCombinations = resourceData.map((obj) => {
        if (obj.locations && obj.locations.length > 0 && obj.resourceCategory) {
            return {
                locality: obj.locations[0].address.locality,
                countryRegion: obj.locations[0].address.countryRegion,
                category: obj.resourceCategory
            };
        }
        return null;
    });

    return filterCombinations;
};

export const removeSpanFromHtmlText = (message: string, id: string) => {
    const parser = new DOMParser();
    const templateMessage = parser.parseFromString(message, 'text/html');

    const paragraph = templateMessage?.getElementById(id);
    paragraph.remove();

    const serializer = new XMLSerializer();
    const modifiedContent = serializer.serializeToString(templateMessage);

    return modifiedContent;
};
