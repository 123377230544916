import React, { useState } from 'react';
import OkCancel from '../components/OkCancel';
import { FormProvider } from '../form/FormContext';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import I18n from '../i18n/I18n';
import { AuthFormHeader, authLinks } from './AccountLinks';
import { Box, Container, IconButton, InputAdornment, Paper } from '@mui/material';
import Nav from '../nav/Nav';
import { confirmPassword } from './api';
import useHistory from '../utils/useHistory';
import { FormContainer } from '../form/FormContainer';
import Validator from '../form/Validator';
import { isEmpty } from 'lodash';
import { Icons } from '../icons/Icons';

const EndAdornment = ({ showPassword, onShowPasswordHandle }) => {
    return (
        <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={onShowPasswordHandle} onMouseDown={(e) => e.preventDefault()} edge="end">
                {showPassword ? <Icons.VisibilityOff /> : <Icons.Visibility />}
            </IconButton>
        </InputAdornment>
    );
};

const validationRules = {
    confirmPassword: [
        Validator.Custom((name, state, isModified) => {
            console.log('password not match', isEmpty(state.confirmPassword) || state.password === state.confirmPassword);
            return isEmpty(state.confirmPassword) || state.password === state.confirmPassword;
        }, 'Password does not match')
    ]
};

export default () => {
    const { changeRoute, goBack } = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        username: '',
        code: '',
        password: '',
        confirmPassword: ''
    });

    const onNext = () => {
        setLoading(true);
        confirmPassword({ username: values.username, code: values.code, password: values.password })
            .then((res: any) => {
                if (res) {
                    changeRoute(authLinks.login.path, undefined, true);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error('login error:', err);
            });
    };

    return (
        <>
            <Nav />
            <Container key="verfiy" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Paper sx={{ maxWidth: '400px', p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <FormProvider state={values} setState={setValues} validationRules={validationRules}>
                            <FormContainer>
                                <FormItem>
                                    <AuthFormHeader title={<I18n token="account.title.set.password" />} />
                                </FormItem>
                                <FormItem>
                                    <FormWidget name="username" label={<I18n token="account.form.email" />} />
                                </FormItem>
                                <FormItem>
                                    <FormWidget name="code" label={<I18n token="account.form.code" />} />
                                </FormItem>
                                <FormItem>
                                    <FormWidget
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        autoFocus
                                        endAdornment={
                                            <EndAdornment
                                                showPassword={showPassword}
                                                onShowPasswordHandle={() => {
                                                    setShowPassword(!showPassword);
                                                }}
                                            />
                                        }
                                        label={<I18n token="account.form.password" />}
                                    />
                                </FormItem>
                                <FormItem>
                                    <FormWidget
                                        name="confirmPassword"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        autoFocus
                                        endAdornment={
                                            <EndAdornment
                                                showPassword={showConfirmPassword}
                                                onShowPasswordHandle={() => {
                                                    setShowConfirmPassword(!showConfirmPassword);
                                                }}
                                            />
                                        }
                                        label={<I18n token="account.form.confirmPassword" />}
                                    />
                                </FormItem>
                                <FormItem>
                                    <OkCancel isBusy={loading} okLabelI18n="dialog.next" cancelLabelI18n="dialog.back" onOk={onNext} onCancel={goBack} isOkDisabled={false} />
                                </FormItem>
                            </FormContainer>
                        </FormProvider>
                    </Paper>
                </Box>
            </Container>
        </>
    );
};
