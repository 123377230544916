import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { AlternateIdInput, Person } from 'sr-types/lib/person/v1/graphql';
import { LOCATION_FIELDS } from '../../common/components/location';
import { client, getConflictedUserDetails } from '../../common/components/person/person';
import { constants } from '../../common/constants';
import { ContactInfoFragment, ReferenceFragment } from '../../common/list/fragments';
import { ValidationRulesType } from '../../production/helpers/productionUtils';
import { BasicInfoInputExtended } from './personBasicInformation';

export type AlternateIdProps = {
    conflictedPerson: Person;
    setConflictedPerson: Dispatch<SetStateAction<Person>>;
    hasAccount: boolean;
    setHasAccount: Dispatch<SetStateAction<boolean>>;
    errorKey: string;
    setErrorKey: Dispatch<SetStateAction<string>>;
    fieldSelected: string;
    setFieldSelected: Dispatch<SetStateAction<string>>;
    conflictedProfileId: string;
    setConflictedProfileId: Dispatch<SetStateAction<string>>;
    setIsSaveDisabled: Dispatch<SetStateAction<boolean>>;
    setDynamicValidationRules: Dispatch<SetStateAction<ValidationRulesType>>;
};

export const IMDB_LABEL = 'Imdb';
export const LINKEDIN_LABEL = 'LinkedIn';

export const IMDB_ID_LABEL = 'imdbProfileId';
export const LINKEDIN_ID_LABEL = 'linkedInProfileId';

const IMDB_HOST = 'www.imdb.com';
const IMDB_ME_HOST = 'imdb.me';
const IMDB_PRO_HOST = 'pro.imdb.com';
const LINKEDIN_HOST = 'linkedin.com';

const LINKEDIN_REGEX = /^[A-Za-z]+\./;
const URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export enum ApplicationContext {
    People = 'PEOPLE',
    Account = 'ACCOUNT'
}

export const DOMAIN_ERROR = 'DomainError';
export const PROFILE_CONFLICT_ERROR = 'ProfileConflictError';
export const UNPARSABLE_ID_ERROR = 'UnparsableError';

export type ValidationError = {
    field: string;
    key: string;
    message: string;
};

export const searchSocialProfile: DocumentNode = gql`
    query SearchSocialProfile($input: SearchSocialProfileInput!) {
        searchSocialProfile(input: $input) {
            errors {
                field
                key
                message
            }
            alternateId {
                label
                value
                url
            }
            hasAccount
            person {
                identity {
                    id
                }
                name {
                    firstName
                    lastName
                }
                alternateIds {
                    label
                    value
                    url
                }
                deiInfo {
                    birthYear
                    disabilityStatus
                    ethnicity
                    gender
                    socioEconomicStatus
                }
                memberships {
                    organizationReference {
                        ...Reference
                    }
                }
                profileImage
                profileImageReference {
                    id
                    label
                }
                summary {
                    headline
                    about
                }
                contactInfo {
                    ...ContactInfo
                }
                professionalRoles
                location {
                    ...LocationFields
                }
                reference {
                    ...Reference
                }
            }
        }
    }
    ${LOCATION_FIELDS}
    ${ReferenceFragment}
    ${ContactInfoFragment}
`;

export const createURLConstructor = (url: string) => {
    return url && URL_REGEX.test(url) ? new URL(url) : undefined;
};

export const validateIMDB = () => {
    return [
        {
            isValid: (prop, state, _isModified, resolveI18nToken) => {
                const value = get(state, prop);
                const imdbURL = createURLConstructor(value);

                return IMDB_HOST === imdbURL?.hostname ? true : resolveI18nToken('person.validation.Imdb');
            }
        }
    ];
};

export const validateLinkedIn = () => {
    return [
        {
            isValid: (prop, state, _isModified, resolveI18nToken) => {
                const value = get(state, prop);
                const linkedInURL = createURLConstructor(value);
                const hostname = linkedInURL?.hostname?.replace(LINKEDIN_REGEX, '');

                return hostname === LINKEDIN_HOST ? true : resolveI18nToken('person.validation.LinkedIn');
            }
        }
    ];
};

const validateAlternateId = (key: string = undefined, field: string = undefined) => {
    return [
        {
            isValid: (prop, state, _isModified, resolveI18nToken) => {
                return key === DOMAIN_ERROR
                    ? resolveI18nToken(`person.validation.${field}`)
                    : key === PROFILE_CONFLICT_ERROR
                      ? false
                      : key === UNPARSABLE_ID_ERROR
                        ? resolveI18nToken('person.validation.unparsable.id.error')
                        : null;
            }
        }
    ];
};

export const findAlternateId = (alternateIds: AlternateIdInput[], label: string) => {
    return alternateIds.find((el) => el.label === label);
};

export const fetchResolveAndCreateValidationRules = async (
    fieldLabel: string,
    fieldState: string,
    profileId: string,
    personId: string,
    applicationContext: string,
    ownerId: string,
    getValidationErrors: (
        profileUrl: string,
        profileId: string,
        personId: string,
        requestContext: string,
        ownerId: string
    ) => Promise<any>,
    setDynamicValidationRules: Dispatch<SetStateAction<ValidationRulesType>>,
    setState: Dispatch<SetStateAction<BasicInfoInputExtended>>,
    setExistingPerson: Dispatch<SetStateAction<Person>>,
    setHasAccount: Dispatch<SetStateAction<boolean>>,
    setErrorKey: Dispatch<SetStateAction<string>>,
    resolve: (value: boolean) => void
) => {
    const isFieldIMDb: boolean = fieldLabel === IMDB_LABEL;
    const isFieldLinkedIn: boolean = fieldLabel === LINKEDIN_LABEL;

    let validationRules: ValidationRulesType = {};

    const clearPersonState = (errorKey: string = undefined) => {
        setErrorKey(errorKey);
        setExistingPerson(undefined);
        setState((prevState) => ({
            ...prevState,
            ...(isFieldIMDb && { imdbAlternateId: null }),
            ...(isFieldLinkedIn && { linkedInAlternateId: null })
        }));
        setHasAccount(undefined);
    };

    const setPersonState = (person: Person, hasAccount: boolean, alternateId: AlternateIdInput) => {
        setState((prevState) => ({
            ...prevState,
            ...(isFieldIMDb && { imdbAlternateId: alternateId }),
            ...(isFieldLinkedIn && { linkedInAlternateId: alternateId })
        }));
        setExistingPerson(person);
        setHasAccount(hasAccount);
    };

    if (fieldState && fieldState !== '') {
        const isFieldHostnameNotValid: boolean = checkLinkInInputField(fieldState, fieldLabel, validationRules);

        if (!isFieldHostnameNotValid) {
            const response = await getValidationErrors(fieldState, profileId, personId, applicationContext, ownerId);

            const errors = response?.data?.searchSocialProfile?.errors;
            const alternateId = response?.data?.searchSocialProfile?.alternateId;
            const person = response?.data?.searchSocialProfile?.person;
            const hasAccount = response?.data?.searchSocialProfile?.hasAccount;

            //change person's state after each call for validating the links
            setPersonState(person, hasAccount, alternateId);

            //handle errors response
            errors && errors.length && handleProfileLinksErrors(errors, fieldLabel, validationRules, setErrorKey);
            resolve?.(errors?.length > 0);
            if (!errors) {
                validationRules[fieldLabel] = null;
                setErrorKey(undefined);
            }
        } else clearPersonState(DOMAIN_ERROR);
    } else {
        clearPersonState();
        validationRules[fieldLabel] = null;
    }

    setDynamicValidationRules((prevState) => ({ ...prevState, ...validationRules }));
};

const checkLinkInInputField = (
    currentUrlLink: string,
    currentActiveField: string,
    validationRules: ValidationRulesType
) => {
    const isFieldIMDb: boolean = currentActiveField === IMDB_LABEL;
    const isFieldLinkedIn: boolean = currentActiveField === LINKEDIN_LABEL;

    const urlConstructor = createURLConstructor(currentUrlLink);

    let hostname: string;
    if (isFieldIMDb) hostname = urlConstructor?.hostname;
    else if (isFieldLinkedIn) hostname = urlConstructor?.hostname?.replace(LINKEDIN_REGEX, '');

    const isImdbInWrongField: boolean =
        isFieldIMDb && hostname !== IMDB_HOST && hostname !== IMDB_ME_HOST && hostname !== IMDB_PRO_HOST;
    const isLinkedInInWrongField: boolean = isFieldLinkedIn && hostname !== LINKEDIN_HOST;

    if (isImdbInWrongField || isLinkedInInWrongField)
        validationRules[currentActiveField] = isImdbInWrongField ? validateIMDB() : validateLinkedIn();

    return isImdbInWrongField || isLinkedInInWrongField;
};

const handleProfileLinksErrors = (
    errors: Array<ValidationError>,
    field: string,
    validationRules: ValidationRulesType,
    setErrorKey: Dispatch<SetStateAction<string>>
) => {
    errors.map((error: ValidationError) => {
        if (error.key === PROFILE_CONFLICT_ERROR) validationRules[field] = validateAlternateId(undefined, field);
        else validationRules[field] = validateAlternateId(error.key, field);
        setErrorKey(error.key);
    });
};

export const handleFetchingConflictedPersonDetailsResponse = (
    email: string,
    ownerId: string,
    onSuccess: (res) => void,
    onError: () => void
) => {
    getConflictedUserDetails(
        {
            page: { from: 0, size: 2000 },
            filters: [
                { identifier: 'entity', value: 'Person' },
                {
                    identifier: 'email',
                    value: email
                }
            ]
        },
        ownerId
    )
        .then((res) => {
            onSuccess(res);
        })
        .catch(() => {
            onError();
        });
};

export const extractPersonDetailsFromSearchQuery = (person) => {
    const { name, organization, role, production, productionRole } = person;
    return {
        Name: name,
        Organization: organization,
        Role: role,
        Production: production,
        ProductionRole: productionRole
    };
};

export const getValidationErrors = (
    profileUrl: string,
    profileId: string,
    personId: string,
    requestContext: string,
    ownerId: string
) => {
    return new Promise((resolve) => {
        client
            .query({
                query: searchSocialProfile,
                variables: {
                    input: {
                        profileUrl,
                        profileId,
                        personId,
                        requestContext
                    }
                },
                context: {
                    headers: {
                        ownerId
                    }
                },
                fetchPolicy: constants.apolloFetchPolicy
            })
            .then(resolve);
    });
};
