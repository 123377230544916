import React from 'react';
import { Box, Typography } from '@mui/material';
import { Attribute } from '../attributes';
import { useStyling } from '../../common/Theme';

type TimeAttributePreviewProps = {
    attribute: Attribute;
};

const getTimeFormatFromTimeString = (input) => {
    const hours = input.slice(0, 2);
    const minutes = input.slice(2, 4);

    return `${hours}:${minutes}`;
};

export default (props: TimeAttributePreviewProps) => {
    const { attribute } = props;
    const { theme } = useStyling();

    return (
        attribute && (
            <Box key={attribute.name} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography fontSize={'small'} color={theme.palette.text.disabled}>
                    {attribute.label}
                </Typography>
                <Typography fontSize={'small'}> {attribute.value ? getTimeFormatFromTimeString(attribute.value) : '-'}</Typography>
            </Box>
        )
    );
};
