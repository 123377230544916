import { SchedulerLineItem, SchedulerResource } from './types';
import {
    DURATION_UNIT_MAPPING,
    SYSTEM_CALENDAR,
    getMergedStartEnd,
    getProjectCalendar,
    productionDataToBryntumFormat,
    productToBryntumResourcesAndCalendars,
    toBryntumDependencies,
    toBryntumEventsAndAssignments,
    toBryntumResourcesAndCalendars,
    toBryntumEventsAndAssignmentsForActivity,
    toSchedulerLineItemFromBryntumEvent,
    toSchedulerLineItemsFromReservations,
    toSchedulerActivityItemsFromProduction
} from './schedulerUtil';
import {
    ArrayHelper,
    CalendarModel,
    DateHelper,
    EventModel,
    SchedulerPro
} from '@bryntum/schedulerpro/schedulerpro.module.js';
import { cloneDeep, isEmpty, remove } from 'lodash';
import {
    API_DATE_FORMAT,
    breakDateRangeIntoMonths,
    destructureSchedule,
    toDateTimeISOWithoutOffset
} from '../common/utils/dateTime';
import { addDays, format, isAfter, isValid } from 'date-fns';
import { constants } from '../common/constants';
import { searchClient, slimResultsQuery } from '../common/list/slimQuery';
import db from '../common/indexedDB';
import { UTILIZATION_UNITS_MAPPING } from '../supply/dashboard/BryntumAdapter';
import { roundUpByDurationUnit } from '../supply/booking/ScheduleCalculator';

export const MAX_CALENDAR_RANGE = 10 * 365 * 24 * 3600000; // 10 yrs in milliseconds

const scheduler = new SchedulerPro({
    project: {
        maxCalendarRange: MAX_CALENDAR_RANGE
    }
});

let calculating = false;
let initialized = false;

const schedulerStore = 'schedulerData';
const schedulerPreviewStore = 'schedulerPreviewData';
const schedulerProductionStore = 'schedulerProductionData';

export const TIMELINE_BOOKING_FILTERS = [
    {
        displayType: 'TextFacet',
        dontfetchData: true,
        value: 'opportunityName_booking',
        label: 'filter.bookingName',
        name: 'opportunityName_booking'
    },
    {
        displayType: 'CustomComboBoxFacet',
        value: 'organization_booking',
        label: 'filter.bookingOrganization',
        name: 'Organization'
    },
    {
        displayType: 'CustomComboBoxFacet',
        value: 'production_booking',
        label: 'filter.production',
        name: 'Production'
    },
    {
        displayType: 'CustomComboBoxFacet',
        value: 'ownerOrganizationName_booking',
        label: 'filter.ownerOrganization',
        name: 'Organization'
    },
    {
        displayType: 'CustomListFacet',
        value: 'status_booking',
        label: 'filter.status',
        name: 'ReservationStatus'
    },
    // {
    //     displayType: 'CustomComboBoxFacet',
    //     value: 'contact_booking',
    //     label: 'filter.contact',
    //     name: 'Person'
    // },
    {
        displayType: 'CustomListFacet',
        value: 'opportunityType_booking',
        label: 'filter.bookingType',
        name: 'OpportunityType'
    },
    {
        displayType: 'CustomListFacet',
        isBoolean: true,
        value: 'primary_booking',
        label: 'filter.primary',
        name: 'primary'
    },
    {
        displayType: 'CustomListFacet',
        isBoolean: true,
        value: 'reservationGuestsAvailable_booking',
        label: 'filter.guests',
        name: 'reservationGuestsAvailable'
    },
    {
        displayType: 'CustomListFacet',
        isBoolean: true,
        value: 'isChild_booking',
        label: 'filter.onlyDetails',
        name: 'isChild'
    }
];

export const PRODUCT_ORG_FILTER = 'ownerOrganizationName_product';

export const TIMELINE_PRODUCT_FILTERS = [
    {
        displayType: 'CustomListFacet',
        value: 'type_product',
        label: 'filter.productType',
        name: 'CatalogType'
    },
    {
        displayType: 'CustomComboBoxFacet',
        dontfetchData: true,
        value: 'locations_product',
        label: 'filter.location',
        name: 'locations_product'
    },
    {
        displayType: 'CustomListFacet',
        value: 'subType_product',
        label: 'filter.subType',
        name: 'CatalogSubType'
    },
    {
        displayType: 'CustomListFacet',
        value: 'offerType_product',
        label: 'filter.offerType',
        name: 'OfferType'
    },
    {
        displayType: 'CustomComboBoxFacet',
        value: 'category_product',
        label: 'filter.category',
        name: 'Category'
    }
];

export const TIMELINE_PRODUCT_FILTERS_BY_VIEW = {
    'home-sched': [
        {
            displayType: 'CustomComboBoxFacet',
            dontfetchData: true,
            value: PRODUCT_ORG_FILTER,
            label: 'filter.ownerOrganizationName',
            name: 'Organization'
        },
        ...TIMELINE_PRODUCT_FILTERS
    ],
    'multiLocation-sched': TIMELINE_PRODUCT_FILTERS,
    'preview-vertical': [
        {
            displayType: 'CustomComboBoxFacet',
            value: 'name_product',
            label: 'filter.productName',
            name: 'Product'
        },
        {
            displayType: 'CustomComboBoxFacet',
            dontfetchData: true,
            value: PRODUCT_ORG_FILTER,
            label: 'filter.ownerOrganizationName',
            name: 'Organization'
        },
        ...TIMELINE_PRODUCT_FILTERS
    ]
};

export const initializeScheduler = (schedulerData, organizationAccountDetails) => {
    if (!initialized && schedulerData) {
        scheduler.project.loadInlineData({
            eventsData: schedulerData.events,
            resourcesData: schedulerData.resources,
            assignmentsData: schedulerData.assignments || [],
            dependenciesData: schedulerData.dependencies
        });
        scheduler.calendars = schedulerData.calendars;
        scheduler.project.calendar =
            getProjectCalendar(schedulerData.calendars, organizationAccountDetails)?.id || undefined;
        initialized = true;
    }
};

const getDataFromIDB = async (activeOrganizationAccount, store: string) => {
    const dataFromIDB = await db
        .table('schedulerStores')
        .where('name')
        .equals(activeOrganizationAccount + `_${store}`)
        .toArray();

    if (!isEmpty(dataFromIDB)) {
        return dataFromIDB[0].value;
    }
    return dataFromIDB;
};

const updateDataToIDB = async (activeOrganizationAccount, store, data) => {
    db.table('schedulerStores')
        .where('name')
        .equals(activeOrganizationAccount + `_${store}`)
        .delete();
    db.table('schedulerStores').add({ name: activeOrganizationAccount + `_${store}`, value: data });
};

export const updateSchedulerData = async (
    store,
    lineItems: SchedulerLineItem[],
    activeOrganizationAccount,
    hasDependencies = false,
    updateDate = false
) => {
    const { events, assignments } = toBryntumEventsAndAssignments(lineItems, hasDependencies);
    const { resources, calendars } = toBryntumResourcesAndCalendars(lineItems);
    const dataFromIDB = await db
        .table('schedulerStores')
        .where('name')
        .equals(activeOrganizationAccount + `_${store}`)
        .toArray();
    if (!isEmpty(dataFromIDB) && dataFromIDB[0].value) {
        const data = dataFromIDB[0].value;
        data.resources.forEach((res) => {
            remove(resources, (r) => r.id === res.id);
        });
        data.calendars = data.calendars.map((cal) => {
            const nc = calendars.find((c) => c.id === cal.id);
            if (nc) {
                cal.intervals?.push(...(nc.intervals ?? []));
                remove(calendars, (c) => c.id === cal.id);
            }
            return cal;
        });
        events.forEach((evt) => {
            remove(data.events, (e: any) => e.lineItemId == evt.id);
            remove(data.assignments, (a: any) => a.lineItemId === evt.id);
        });

        data.events = [...data.events, ...events];
        data.assignments = [...data.assignments, ...assignments];
        if (updateDate) {
            data.lastFetchedAt = new Date();
        }
        updateDataToIDB(activeOrganizationAccount, store, data);
        initialized = false;
    }
};

export const updateResourceData = (activeOrganizationAccount) => {
    getDataFromIDB(activeOrganizationAccount, schedulerPreviewStore).then((data) => {
        if (!isEmpty(data)) {
            getProductsData(activeOrganizationAccount).then((res) => {
                const products = res?.data?.results?.hits?.items;
                const { resources } = productToBryntumResourcesAndCalendars(products);
                data.resources = resources;
                updateDataToIDB(activeOrganizationAccount, schedulerPreviewStore, data);
            });
        }
    });
};

export const deleteResourceData = async (resourceID, activeOrganizationAccount) => {
    getDataFromIDB(activeOrganizationAccount, schedulerPreviewStore).then((data) => {
        if (!isEmpty(data)) {
            remove(data.resources, (r: SchedulerResource) => {
                return r.id === resourceID;
            });
            updateDataToIDB(activeOrganizationAccount, schedulerPreviewStore, data);
        }
    });
};

export const deleteLinesFromSchedulerData = async (eventsToDelete, activeOrganizationAccount) => {
    const stores = [schedulerPreviewStore, schedulerStore];

    stores.map((store) => {
        getDataFromIDB(activeOrganizationAccount, store).then((data) => {
            if (!isEmpty(data)) {
                eventsToDelete.forEach((eventID) => {
                    remove(data.events, (e: any) => e.lineItemId === eventID);
                    remove(data.assignments, (a: any) => a.lineItemId === eventID);
                });

                updateDataToIDB(activeOrganizationAccount, store, data);
            }
        });
    });
};

export const deleteBookingFromSchedulerData = async (opportunityId: string, activeOrganizationAccount) => {
    getDataFromIDB(activeOrganizationAccount, schedulerPreviewStore).then((data) => {
        if (!isEmpty(data)) {
            const eventsToDelete = [];
            remove(data.events, (e: any) => {
                if (e.id === opportunityId) eventsToDelete.push(e.id);
                return e.opportunityId === opportunityId;
            });
            remove(data.assignments, (a: any) => eventsToDelete.includes(a.eventId));
            updateDataToIDB(activeOrganizationAccount, schedulerPreviewStore, data);
        }
    });
};

export const refreshSchedulerDataAttributes = async (opportunity, activeOrganizationAccount) => {
    const attributes = opportunity[0].attributes;

    getDataFromIDB(activeOrganizationAccount, schedulerPreviewStore).then((data) => {
        if (!isEmpty(data)) {
            data.events.map((event) => {
                if (event.attributes.opportunityId === attributes.opportunityId) {
                    event.status = attributes.status;
                    event.attributes.name = attributes.name;
                    event.attributes.contact = attributes.contact;
                    event.attributes.opportunityName = attributes.opportunityName;
                    event.attributes.organizationName = attributes.organizationName;
                    event.attributes.production = attributes.production;
                    event.attributes.organization = attributes.organization;
                    event.attributes.opportunityType = attributes.opportunityType;
                    event.attributes.contact = attributes.contact;
                    event.attributes.status = attributes.status;
                }
            });
            updateDataToIDB(activeOrganizationAccount, schedulerPreviewStore, data);
        }
    });
};

type EventDates = {
    startDate?: string;
    durationUnit?: string;
    endDate?: string;
    duration?: number;
    calendar?: string;
};

export const scheduleItems = (lineItems: SchedulerLineItem[], sequenceItems = false, id: string) => {
    return new Promise(async (resolve, reject) => {
        if (!initialized) {
            reject('scheduler is not initialized');
        }
        if (calculating) {
            reject('scheduler is busy');
        } else {
            calculating = true;
            const { events, assignments } = toBryntumEventsAndAssignments(lineItems, sequenceItems);
            let record = scheduler.eventStore.getById(id) as EventModel;

            // add event if it doesn't exist
            if (!record) {
                scheduler.project.eventStore.add(events);
                scheduler.project.assignmentStore.add(assignments);
                if (sequenceItems) {
                    const dependencies = toBryntumDependencies(lineItems);
                    scheduler.project.dependencyStore.add(dependencies);
                }
                await scheduler.project.commitAsync();
                record = scheduler.eventStore.getById(id) as EventModel;
            }

            // add dependencies if applicable
            const dependencyRecords = scheduler.project.dependencyStore.getEventDependencies(record);
            if (dependencyRecords.length == 0) {
                if (sequenceItems) {
                    lineItems.map(async (li, idx) => {
                        const event = scheduler.eventStore.getById(li.id) as EventModel;
                        if (idx != 0) {
                            await event.setAsync({ manuallyScheduled: false });
                        }
                    });
                    const dependencies = toBryntumDependencies(lineItems);
                    scheduler.project.dependencyStore.add(dependencies);
                }
            } else {
                if (!sequenceItems) {
                    lineItems.map(async (li) => {
                        const event = scheduler.eventStore.getById(li.id) as EventModel;
                        await event.setAsync({ manuallyScheduled: true });
                        dependencyRecords.push(...scheduler.project.dependencyStore.getEventDependencies(event));
                    });
                    scheduler.project.dependencyStore.remove(dependencyRecords);
                }
            }
            await scheduler.project.commitAsync();

            // update event dates, resources
            const updatedEvents = await Promise.all(
                lineItems.map(async (li, idx: number) => {
                    const eventRecord = scheduler.eventStore.getById(li.id) as EventModel;

                    const { mergedStart, mergedEnd } = getMergedStartEnd(li.dateRange);
                    const startValid = isValid(mergedStart);
                    const endValid = isValid(mergedEnd);
                    let duration = undefined;

                    const resources = li.resources.map((r) => scheduler.resourceStore.getById(r));
                    const resourceChanges = ArrayHelper.delta(resources, eventRecord.resources);

                    let updatedEvent: EventDates = {
                        startDate: startValid ? toDateTimeISOWithoutOffset(mergedStart) : undefined,
                        durationUnit: DURATION_UNIT_MAPPING.get(li.dateRange.dateTimeDuration?.unit),
                        calendar: li.calendar
                    };

                    updatedEvent['dateRange'] = li.dateRange;
                    const isHourly = li.dateRange.dateTimeDuration?.unit === 'hour';
                    if (li.dateRange.durationSpecified) {
                        updatedEvent['duration'] = li.dateRange.dateTimeDuration?.value;
                    } else {
                        if (endValid) {
                            updatedEvent['endDate'] = li.dateRange.durationSpecified
                                ? undefined
                                : isHourly
                                  ? toDateTimeISOWithoutOffset(mergedEnd)
                                  : toDateTimeISOWithoutOffset(DateHelper.add(mergedEnd, 1, 'd'));
                        } else {
                            updatedEvent['endDate'] = undefined;
                        }
                    }

                    // update event duration w/o any start/end dates for autoscheduled lines
                    if (sequenceItems) {
                        if (idx !== 0) {
                            updatedEvent = {
                                duration: li.dateRange.dateTimeDuration?.value,
                                calendar: li.calendar,
                                durationUnit: DURATION_UNIT_MAPPING.get(li.dateRange.dateTimeDuration?.unit)
                            };
                        }
                    }
                    await eventRecord.setAsync(updatedEvent);
                    if (resourceChanges?.toAdd?.length > 0 || resourceChanges?.toRemove?.length > 0) {
                        scheduler.eventStore.unassignEventFromResource(eventRecord, resourceChanges.toRemove);
                        scheduler.eventStore.assignEventToResource(eventRecord, resourceChanges.toAdd);
                    }
                    return eventRecord;
                })
            );
            calculating = false;
            resolve(toSchedulerLineItemFromBryntumEvent(updatedEvents ?? []));
        }
    });
};

export const onCancelEvent = () => {
    scheduler.project.eventStore.revertChanges();
    scheduler.project.dependencyStore.revertChanges();
    scheduler.project.assignmentStore.revertChanges();
};

export const onSaveEvent = async () => {
    scheduler.project.commit();
};

const PRODUCT_DEFAULT_FILTERS = [
    { identifier: 'inactive', value: 'false' },
    { identifier: 'reservable', value: 'true' }
];
export const getProductsData = (activeOrganizationAccount) => {
    return searchClient.query({
        query: slimResultsQuery('Product'),
        variables: {
            filters: [{ identifier: 'entity', value: 'Product' }, ...PRODUCT_DEFAULT_FILTERS],
            page: {
                from: 0,
                size: 1000
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });
};

export const getResourceData = (activeOrganizationAccount) => {
    return searchClient.query({
        query: slimResultsQuery('Resource'),
        variables: {
            filters: [{ identifier: 'entity', value: 'Resource' }],
            page: {
                from: 0,
                size: 1000
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });
};

export const getProductionData = (activeOrganizationAccount) => {
    return searchClient.query({
        query: slimResultsQuery('Production'),
        variables: {
            filters: [{ identifier: 'entity', value: 'Production' }],
            page: {
                from: 0,
                size: 1000
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });
};

export const getSchedulerData = async (activeOrganizationAccount) => {
    let data = await db
        .table('schedulerStores')
        .where('name')
        .equals(activeOrganizationAccount + `_${schedulerPreviewStore}`)
        .toArray();

    return new Promise((resolve, reject) => {
        if (!isEmpty(data)) {
            resolve(data[0].value);
        } else {
            getProductsData(activeOrganizationAccount).then((res) => {
                const products = res?.data?.results?.hits?.items;
                searchClient
                    .query({
                        query: slimResultsQuery('Reservation'),
                        variables: {
                            filters: [{ identifier: 'entity', value: 'Reservation' }],
                            page: {
                                from: 0,
                                size: 2000
                            }
                        },
                        fetchPolicy: constants.apolloFetchPolicy,
                        context: {
                            headers: {
                                ownerId: activeOrganizationAccount
                            }
                        }
                    })
                    .then((res) => {
                        const schedulerLineItems = toSchedulerLineItemsFromReservations(
                            res?.data?.results?.hits?.items ?? []
                        );
                        const { resources, calendars } = productToBryntumResourcesAndCalendars(products);
                        const { events, assignments } = toBryntumEventsAndAssignments(schedulerLineItems);
                        const data = {
                            events: events,
                            resources: resources,
                            assignments: assignments,
                            calendars: calendars
                        };
                        db.table('schedulerStores').add({
                            name: activeOrganizationAccount + `_${schedulerPreviewStore}`,
                            value: data
                        });
                        resolve(data);
                    });
            });
        }
    });
};

export const getFilterData = async (activeOrganizationAccount, TIMELINE_FILTERS) => {
    const data = {
        results: {
            facets: [],
            hits: {
                page: {
                    total: 0
                }
            }
        }
    };

    const promises = TIMELINE_FILTERS.map(async (filter) => {
        const entriesArr = [];
        const filters = [{ identifier: 'entity', value: filter.name }];

        if (filter.name === 'Product') {
            filters.push(...PRODUCT_DEFAULT_FILTERS);
        }
        if (!filter.isBoolean && !filter.dontfetchData) {
            const res = await searchClient.query({
                query: slimResultsQuery(filter.name),
                variables: {
                    filters: filters,
                    page: {
                        from: 0,
                        size: 1000
                    }
                },
                fetchPolicy: constants.apolloFetchPolicy,
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            });
            const filterData = res?.data?.results?.hits?.items;
            filterData.map((f) => {
                entriesArr.push({
                    id: filter.name + '_' + f.name,
                    count: 1,
                    label: f.name,
                    __typename: 'SKFacetSetEntry'
                });
            });
        } else if (filter.isBoolean) {
            entriesArr.push({
                id: filter.name + '_' + true,
                count: 1,
                label: true,
                __typename: 'SKFacetSetEntry'
            });
        }

        return {
            identifier: filter.value,
            type: 'RefinementSelectFacet',
            label: filter.label,
            display: filter.displayType,
            entries: entriesArr
        };
    });

    const results = await Promise.all(promises);
    results.forEach((result) => {
        data.results.facets.push(result);
    });

    return data;
};

export const toBryntumFormat = (resdata, products, calendar) => {
    const schedulerLineItems = toSchedulerLineItemsFromReservations(resdata ?? []);
    const { resources, calendars } = productToBryntumResourcesAndCalendars(products);
    const { events, assignments } = toBryntumEventsAndAssignments(schedulerLineItems);
    // filter out old data where startDate is after EndDate
    const validEvents = events.filter((event) => {
        const { mergedStart, mergedEnd } = getMergedStartEnd(event.dateRange);
        return !isAfter(mergedStart, mergedEnd);
    });
    const data = {
        events: validEvents,
        resources: resources,
        assignments: assignments,
        calendars: [...calendars, ...(calendar || [])],
        lastFetchedAt: new Date()
    };
    return data;
};

export const fetchReservationData = async (activeOrganizationAccount, from, batchSize, filter) => {
    try {
        let filters = [{ identifier: 'entity', value: 'Reservation' }];
        filters.push(filter);
        const initialResponse = await searchClient.query({
            query: slimResultsQuery('Reservation'),
            variables: {
                filters: filters,
                page: {
                    from: from,
                    size: batchSize
                }
            },
            fetchPolicy: constants.apolloFetchPolicy,
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        });
        return initialResponse;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const UpdateIDB = (activeOrganizationAccount, store, data) => {
    updateDataToIDB(activeOrganizationAccount, store, data);
};

export const getCachedData = async (activeOrganizationAccount) => {
    let data = await db
        .table('schedulerStores')
        .where('name')
        .equals(activeOrganizationAccount + `_${schedulerPreviewStore}`)
        .toArray();

    return !isEmpty(data) ? data[0].value : {};
};

export const getCalendarWorkingDays = (line) => {
    const lineItem = cloneDeep(line);
    const dateRange = destructureSchedule(lineItem.dateRange);
    const lineCal: CalendarModel = lineItem.calendar?.id
        ? (scheduler.project.calendarManagerStore.getById(lineItem.calendar.id) as CalendarModel)
        : (scheduler.project.calendarManagerStore.findRecord('name', SYSTEM_CALENDAR) as CalendarModel);

    if (
        lineCal &&
        isValid(dateRange.mergedStart) &&
        isValid(dateRange.mergedEnd) &&
        lineItem.dateRange.dateTimeDuration.unit
    ) {
        const durationInMS = lineCal.calculateDurationMs(
            lineItem['dateRange'].dateTimeDuration.unit == 'hour' ? dateRange.mergedStart : dateRange.start,
            lineItem['dateRange'].dateTimeDuration.unit == 'hour' || lineItem['dateRange'].dateTimeDuration.value === 0
                ? dateRange.mergedEnd
                : addDays(dateRange.end, 1)
        );
        lineItem.dateRange.dateTimeDuration.value = DateHelper.as(
            lineItem.dateRange.dateTimeDuration.unit,
            durationInMS,
            'ms'
        );
    }
    lineItem.dateRange.dateTimeDuration.value = roundUpByDurationUnit(
        lineItem.dateRange.dateTimeDuration.unit,
        lineItem.dateRange.dateTimeDuration.value
    );
    return lineItem.dateRange;
};

export const getEventAllocationData = (lineItem) => {
    const dateRange = destructureSchedule(lineItem.dateRange);
    const dateBuckets = breakDateRangeIntoMonths(
        dateRange.mergedStart,
        dateRange.mergedEnd,
        lineItem.dateRange.dateTimeDuration.unit
    );

    const allocationData = dateBuckets.map((bucket) => {
        const lineCal = lineItem.calendar?.id
            ? (scheduler.project.calendarManagerStore.getById(lineItem.calendar.id) as CalendarModel)
            : (scheduler.project.calendarManagerStore.findRecord('name', SYSTEM_CALENDAR) as CalendarModel);
        const workingMsEvent = lineCal.calculateDurationMs(
            bucket[0],
            lineItem['dateRange'].dateTimeDuration.unit == 'hour' || lineItem['dateRange'].dateTimeDuration.value === 0
                ? bucket[1]
                : addDays(bucket[1], 1)
        );
        const allocationDataUnit = lineItem['dateRange'].dateTimeDuration.unit === 'hour' ? 'hour' : 'day';
        return {
            startDate: format(bucket[0], API_DATE_FORMAT),
            endDate: format(bucket[1], API_DATE_FORMAT),
            periodUnit: 'Month',
            unit: UTILIZATION_UNITS_MAPPING.get(allocationDataUnit),
            value: DateHelper.as(allocationDataUnit, workingMsEvent, 'ms')
        };
    });
    return allocationData;
};

export const updateAndIntializeSchedulerData = (
    setSchedulerData,
    storeData,
    activeOrganizationAccount,
    indexDBName,
    organizationAccountDetails
) => {
    setSchedulerData(storeData);
    UpdateIDB(activeOrganizationAccount, indexDBName, storeData);
    initializeScheduler(storeData, organizationAccountDetails);
};

const updateDataToIDBAndIsSchedulerProductionRefetch = async (
    activeOrganizationAccount,
    data,
    setIsSchedulerProductionRefetchNeeded,
    isSchedulerProductionRefetchNeeded
) => {
    db.table('schedulerStores')
        .where('name')
        .equals(activeOrganizationAccount + `_${schedulerProductionStore}`)
        .delete();
    db.table('schedulerStores')
        .add({ name: activeOrganizationAccount + `_${schedulerProductionStore}`, value: data })
        .then(() => {
            const newIsSchedulerRefetchNeeded: boolean = !isSchedulerProductionRefetchNeeded;
            setIsSchedulerProductionRefetchNeeded(newIsSchedulerRefetchNeeded);
            localStorage.setItem('isSchedulerProductionRefetchNeeded', newIsSchedulerRefetchNeeded ? 'true' : 'false');
        });
};

export const deleteActivitiesFromSchedulerProductionData = async (
    eventID,
    activeOrganizationAccount,
    setIsSchedulerProductionRefetchNeeded = undefined,
    isSchedulerProductionRefetchNeeded = undefined
) => {
    getDataFromIDB(activeOrganizationAccount, schedulerProductionStore).then((data) => {
        if (!isEmpty(data)) {
            remove(data.events, (e: any) => e.id === eventID);
            remove(data.assignments, (a: any) => a.eventId === eventID);

            updateDataToIDBAndIsSchedulerProductionRefetch(
                activeOrganizationAccount,
                data,
                setIsSchedulerProductionRefetchNeeded,
                isSchedulerProductionRefetchNeeded
            );
        }
    });
};

export const updateSchedulerProductionData = async (
    eventLineitem,
    activeOrganizationAccount,
    setIsSchedulerProductionRefetchNeeded,
    isSchedulerProductionRefetchNeeded
) => {
    const { events, assignments } = toBryntumEventsAndAssignmentsForActivity([eventLineitem]);
    getDataFromIDB(activeOrganizationAccount, schedulerProductionStore).then((data) => {
        if (!isEmpty(data)) {
            events.forEach((evt) => {
                remove(data.events, (e: any) => e.id === evt.id);
                remove(data.assignments, (a: any) => a.eventId === evt.id);
            });

            data.events = [...data.events, ...events];
            data.assignments = [...data.assignments, ...assignments];
            updateDataToIDBAndIsSchedulerProductionRefetch(
                activeOrganizationAccount,
                data,
                setIsSchedulerProductionRefetchNeeded,
                isSchedulerProductionRefetchNeeded
            );
            initialized = false;
        }
    });
};

export const updateProductionResourceData = (
    productionData,
    activeOrganizationAccount,
    setIsSchedulerProductionRefetchNeeded,
    isSchedulerProductionRefetchNeeded
) => {
    const { production } = productionDataToBryntumFormat(productionData);
    getDataFromIDB(activeOrganizationAccount, schedulerProductionStore).then((data) => {
        if (!isEmpty(data)) {
            production.forEach((prodItem) => {
                remove(data.resources, (r: any) => r.id === prodItem.id);
            });
            data.resources = [...data.resources, ...production];
            updateDataToIDBAndIsSchedulerProductionRefetch(
                activeOrganizationAccount,
                data,
                setIsSchedulerProductionRefetchNeeded,
                isSchedulerProductionRefetchNeeded
            );
        }
    });
};

export const addNewProductionInResourceAndEventsData = (
    productionData,
    activeOrganizationAccount,
    setIsSchedulerProductionRefetchNeeded,
    isSchedulerProductionRefetchNeeded,
    changeSearch
) => {
    const eventLineItems = [];
    if (productionData.plans?.[0].activities?.length) {
        const productionEventData = {
            genre: productionData.genre,
            id: productionData.identity.id,
            otherTitles: productionData.otherTitles,
            productionStatus: productionData.productionStatus,
            publicTitle: productionData.publicTitle,
            productionType: productionData.productionType
        };
        productionData.plans[0].activities.forEach((activity) => {
            const activityItem = {
                ...activity,
                id: activity.identity.id,
                production: { ...productionEventData }
            };
            delete activityItem.identity;
            eventLineItems.push(toSchedulerActivityItemsFromProduction(activityItem));
        });
    }
    const { production } = productionDataToBryntumFormat(productionData);

    getDataFromIDB(activeOrganizationAccount, schedulerProductionStore).then((data) => {
        if (!isEmpty(data)) {
            if (eventLineItems.length) {
                const { events, assignments } = toBryntumEventsAndAssignmentsForActivity(eventLineItems);
                events.forEach((evt) => {
                    remove(data.events, (e: any) => e.id === evt.id);
                    remove(data.assignments, (a: any) => a.eventId === evt.id);
                });
                data.events = [...data.events, ...events];
                data.assignments = [...data.assignments, ...assignments];
            }

            production.forEach((prodItem) => {
                remove(data.resources, (r: any) => r.id === prodItem.id);
            });
            data.resources = [...data.resources, ...production];
            updateDataToIDBAndIsSchedulerProductionRefetch(
                activeOrganizationAccount,
                data,
                setIsSchedulerProductionRefetchNeeded,
                isSchedulerProductionRefetchNeeded
            );
        }
    });
    changeSearch({ newProduction: undefined });
};

export const deleteProductionResourcesFromSchedulerProductionData = async (
    productionId,
    activeOrganizationAccount,
    setIsSchedulerProductionRefetchNeeded,
    isSchedulerProductionRefetchNeeded
) => {
    getDataFromIDB(activeOrganizationAccount, schedulerProductionStore).then((data) => {
        if (!isEmpty(data)) {
            remove(data.resources, (r: any) => r.id === productionId);

            updateDataToIDBAndIsSchedulerProductionRefetch(
                activeOrganizationAccount,
                data,
                setIsSchedulerProductionRefetchNeeded,
                isSchedulerProductionRefetchNeeded
            );
        }
    });
};
