import { Button, Divider, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid-premium';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useUnitsData } from '../../attributes/attributesApi';
import Loading from '../../common/components/Loading';
import { FormContainer } from '../../common/form/FormContainer';
import { FormContext } from '../../common/form/FormContext';
import FormItem from '../../common/form/FormItem';
import FormModal from '../../common/form/FormModal';
import FormPanel from '../../common/form/FormPanel';
import FormWidget from '../../common/form/FormWidget';
import ScheduleWidget from '../../common/form/widgets/ScheduleWidget';
import I18n, { useI18n } from '../../common/i18n/I18n';
import { Icons } from '../../common/icons/Icons';
import { useStyling } from '../../common/Theme';
import { ActivityOrder, EMPTY_DEPENDENCIES } from '../helpers/activityUtils';
import { useActiviesData } from '../helpers/productionApi';
import { EMPTY_PERSON_MODAL_STATE } from '../../shortlists/ShortlistLineItemEditor';
import AddProjectOwnerModal, { doSavePerson } from '../form/production/AddProjectOwnerModal';
import { extractNames } from '../helpers/peopleUtils';
import PersonOptionRenderer from '../../shortlists/form/PersonOptionRenderer';

const ActivityEditorContent = ({
    state,
    showBasic,
    resourceOptions,
    activitiesLoading,
    activityOptions,
    unitOptions,
    unitsLoading,
    setState,
    isResourceForm,
    onCreateOwner
}) => {
    const { theme } = useStyling();
    const usedOptions = useMemo(() => {
        return Object.keys(state.dependencies);
    }, [state.dependencies]);
    const lastElement = useRef(null);

    const scrollToBottom = () => {
        lastElement.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const locationsLabel = useI18n('production.activityForm.locations');
    const categoryLabel = useI18n('production.activityForm.category');
    const associateWithResourceLabel = useI18n('production.activityForm.associateWithResource');

    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <Typography sx={{ color: theme.palette.primary.main, my: 1 }}>
                        <I18n token="production.activityForm.basic" />
                    </Typography>
                </FormItem>
                <FormItem half>
                    <FormWidget name={'name'} label={useI18n('production.activityForm.name')} />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        name={'activityType'}
                        component="MetadataAutocomplete"
                        label={useI18n('production.activityForm.type')}
                        metadataName={'ActivityType'}
                    />
                </FormItem>
                <FormItem>
                    <Divider sx={{ width: '100%' }} />
                </FormItem>
                <FormItem>
                    <Typography sx={{ color: theme.palette.primary.main, my: 1 }}>
                        <I18n token="production.activityForm.schedule" />
                    </Typography>
                </FormItem>
                <FormItem span={12} sx={{ mb: 2 }} hidden={state?.activityType === 'Milestone'}>
                    <FormWidget
                        hideHelperText
                        component={'Switch'}
                        name="dateRange.durationSpecified"
                        label={useI18n('production.activityForm.specifyDuration')}
                    />
                </FormItem>
                <ScheduleWidget
                    showFullSchedule={true}
                    name="dateRange"
                    allowedUnits={['hour', 'day', 'week']}
                    isMilestone={state?.activityType === 'Milestone'}
                />
                {!showBasic && (
                    <>
                        <FormItem>
                            <Divider sx={{ width: '100%' }} />
                        </FormItem>
                        <FormItem>
                            <Typography sx={{ color: theme.palette.primary.main, my: 1 }}>
                                <I18n token="production.activityForm.details" />
                            </Typography>
                        </FormItem>
                        <FormItem half>
                            <FormWidget component="Location" name="locations" label={locationsLabel} multiple />
                        </FormItem>
                        <FormItem half>
                            <FormWidget
                                component="MetadataAutocomplete"
                                metadataName="Category"
                                name="category"
                                label={categoryLabel}
                            />
                        </FormItem>
                        {!isResourceForm && (
                            <FormItem>
                                <FormWidget
                                    component="Autocomplete"
                                    name="associateWithResource"
                                    label={associateWithResourceLabel}
                                    options={resourceOptions}
                                    multiple
                                />
                            </FormItem>
                        )}
                        <FormItem>
                            <Divider sx={{ width: '100%' }} />
                        </FormItem>
                        <FormItem>
                            <Typography sx={{ color: theme.palette.primary.main, my: 1, textTransform: 'uppercase' }}>
                                <I18n token="production.form.title.track" />
                            </Typography>
                        </FormItem>
                        <FormItem half>
                            <FormWidget
                                component="ReferenceAutocomplete"
                                entity="Person"
                                name="activityOwner"
                                label={<I18n token="production.form.field.owner" />}
                                addOption
                                OptionRenderer={PersonOptionRenderer}
                                getOption={(item) => {
                                    return {
                                        id: item.id,
                                        name: item.name,
                                        role: item.role,
                                        label: item.name,
                                        ref: { ...item.reference, label: item.name }
                                    };
                                }}
                                onCreateHandle={onCreateOwner}
                                preload={false}
                            />
                        </FormItem>
                        <FormItem half>
                            <FormWidget
                                component="MetadataAutocomplete"
                                metadataName="Termset"
                                filters={[{ identifier: 'name', value: 'ActivityStatus' }]}
                                termset="ActivityStatus"
                                name="activityStatus"
                                label={<I18n token="production.form.field.status" />}
                                allowCreate
                            />
                        </FormItem>
                        {Object.keys(state.dependencies)
                            .filter((key) => key !== 'lastDependency')
                            .map((item) => (
                                <ActivityDependencyFormItems
                                    activitiesLoading={activitiesLoading}
                                    activityOptions={activityOptions}
                                    unitOptions={unitOptions}
                                    unitsLoading={unitsLoading}
                                    id={item}
                                    key={item}
                                    onDelete={(id) => {
                                        setState((prevVal) => {
                                            const { [id]: removedDependency, ...otherDependencies } =
                                                prevVal.dependencies;
                                            return { ...prevVal, dependencies: { ...otherDependencies } };
                                        });
                                    }}
                                />
                            ))}

                        <ActivityDependencyFormItems
                            activitiesLoading={activitiesLoading}
                            activityOptions={
                                activityOptions
                                    ? activityOptions.filter((option) => !usedOptions.includes(option.id))
                                    : []
                            }
                            unitOptions={unitOptions}
                            unitsLoading={unitsLoading}
                            id={'lastDependency'}
                            onDelete={() => {
                                setState((prevVal) => {
                                    return {
                                        ...prevVal,
                                        dependencies: {
                                            ...prevVal.dependencies,
                                            ...cloneDeep(EMPTY_DEPENDENCIES)
                                        }
                                    };
                                });
                            }}
                        />
                        <FormItem>
                            <Button
                                ref={lastElement}
                                sx={{ alignSelf: 'start' }}
                                disabled={
                                    activityOptions
                                        ? activityOptions.filter((option) => !usedOptions.includes(option.id)).length <=
                                              1 || !state.dependencies?.lastDependency?.activityRefId
                                        : true
                                }
                                onClick={() => {
                                    setState((prevVal) => {
                                        return {
                                            ...prevVal,
                                            dependencies: {
                                                ...prevVal.dependencies,
                                                ...cloneDeep(EMPTY_DEPENDENCIES),
                                                [prevVal.dependencies.lastDependency.activityRefId]: {
                                                    ...prevVal.dependencies.lastDependency
                                                }
                                            }
                                        };
                                    });
                                    setTimeout(() => {
                                        scrollToBottom();
                                    }, 300);
                                }}
                            >
                                <Icons.AddButton /> <I18n token={'production.activity'} />
                            </Button>
                        </FormItem>
                    </>
                )}
            </FormContainer>
        </FormPanel>
    );
};

const ActivityDependencyFormItems = ({
    id,
    onDelete,
    activityOptions,
    activitiesLoading,
    unitOptions,
    unitsLoading
}) => {
    const { handleChange, state } = useContext(FormContext);

    return (
        <>
            <FormItem>
                <Divider sx={{ width: '100%', mb: 2 }} />
            </FormItem>
            <FormItem>
                <FormWidget
                    component="ToggleButtonGroup"
                    color="primary"
                    value={state?.dependencies?.[id]?.order}
                    exclusive
                    sx={{ mb: 2 }}
                    onChange={(e, val) => {
                        handleChange(`dependencies.${id}.order`, val);
                    }}
                    options={[
                        { id: ActivityOrder.predecessor, label: useI18n('form.activity.predecessor') },
                        { id: ActivityOrder.successor, label: useI18n('form.activity.successor') }
                    ]}
                ></FormWidget>
            </FormItem>
            <FormItem>
                <FormWidget
                    label={useI18n('production.activity')}
                    name={`dependencies.${id}.activityRefId`}
                    component="Autocomplete"
                    loading={activitiesLoading}
                    options={activityOptions}
                    disableClearable
                    disabled={id !== 'lastDependency'}
                />
                <IconButton
                    size="small"
                    color="inherit"
                    sx={{ height: 'fit-content' }}
                    onClick={() => {
                        onDelete(id);
                    }}
                >
                    <GridCloseIcon />
                </IconButton>
            </FormItem>
            <FormItem half>
                <FormWidget
                    component={'Number'}
                    name={`dependencies.${id}.lag`}
                    label={useI18n('production.activityForm.dependencyFollowBy')}
                    forcePositive={true}
                />
            </FormItem>
            <FormItem half>
                <FormWidget
                    label={useI18n('production.activityForm.dependencyUnit')}
                    name={`dependencies.${id}.lagUnit`}
                    component="Autocomplete"
                    loading={unitsLoading}
                    options={unitOptions}
                    disableClearable
                />
            </FormItem>
            <FormItem>
                <FormWidget
                    component="MetadataAutocomplete"
                    name={`dependencies.${id}.type`}
                    metadataName="DependencyType"
                    label={useI18n('production.activityForm.dependencyType')}
                    identifier="termset"
                />
            </FormItem>
        </>
    );
};

const VALID_UNITS = ['Hours', 'Days', 'Weeks', 'Months', 'Years'];

export default ({
    isOpen,
    setIsOpen,
    onClose,
    handleSave,
    isLoading,
    isSaving,
    resourceOptions,
    productionId,
    activityId = undefined,
    showBasic,
    isInModal,
    setIsSaveDisabled,
    isResourceForm
}) => {
    const { validation, state, setState } = useContext(FormContext);
    const { activitiesData, activitiesLoading } = useActiviesData(productionId);
    const [personModalState, setPersonModalState] = useState(EMPTY_PERSON_MODAL_STATE);
    const guestEditorRef = useRef(null);
    const [isFormSaving, setIsFormSaving] = useState(false);

    const { unitsData, unitsLoading } = useUnitsData('Time');

    const unitOptions = useMemo(() => {
        if (unitsData?.results?.hits?.items) {
            return unitsData.results.hits.items
                .filter((item) => VALID_UNITS.includes(item.label))
                .map((item) => {
                    return { id: item.label, label: item.label };
                });
        }
        return [];
    }, [unitsData]);

    const activityOptions = useMemo(() => {
        if (activitiesData?.results?.hits?.items) {
            return activitiesData.results.hits.items
                .filter((item) => item.id !== activityId)
                .map((item) => {
                    return { id: item.id, label: item.name };
                });
        }
    }, [activitiesData?.results?.hits?.items, activityId]);

    useEffect(() => {
        setIsSaveDisabled?.((validation && !validation.isValid) || isSaving);
    }, [isSaving, setIsSaveDisabled, validation]);

    const onSave = () => {
        if (personModalState.open)
            doSavePerson(
                setIsFormSaving,
                (input) => {
                    setState({ ...state, activityOwner: input });
                },
                guestEditorRef,
                setPersonModalState
            );
        else handleSave();
    };

    const onCreateOwner = (e) => {
        setPersonModalState({
            open: true,
            prepopulate: { name: extractNames(e.label) }
        });
    };

    const additonalLeftsideActions = personModalState.open
        ? [
              {
                  label: useI18n('person.import.contacts.uploadfile.modal.back'),
                  onClick: () => {
                      setPersonModalState(EMPTY_PERSON_MODAL_STATE);
                  }
              }
          ]
        : [];

    return isInModal ? (
        <FormModal
            isOpen={isOpen}
            width="700px"
            onClose={onClose}
            title={
                personModalState.open ? (
                    <I18n token="form.shortlist.guestEditor" />
                ) : activityId ? (
                    <I18n token="production.activity.edit" />
                ) : (
                    <I18n token="production.activity.new" />
                )
            }
            id={`production-schedule`}
            isSaving={isFormSaving}
            hideDefaultButtons={true}
            additionalActions={[
                !personModalState.open && {
                    label: useI18n('dialog.cancel'),
                    onClick: () => {
                        setIsOpen(false);
                    },
                    variant: 'text'
                },
                {
                    label: useI18n('dialog.save'),
                    onClick: () => {
                        onSave();
                    },
                    variant: 'contained',
                    disabled: (validation && !validation.isValid) || isSaving
                }
            ]}
            additonalLeftsideActions={additonalLeftsideActions}
        >
            {isLoading ? (
                <Loading />
            ) : personModalState.open ? (
                <AddProjectOwnerModal
                    personModalState={personModalState}
                    setIsSaveDisabled={setIsSaveDisabled}
                    guestEditorRef={guestEditorRef}
                />
            ) : (
                <ActivityEditorContent
                    state={state}
                    showBasic={showBasic}
                    resourceOptions={resourceOptions}
                    activitiesLoading={activitiesLoading}
                    activityOptions={activityOptions}
                    unitOptions={unitOptions}
                    unitsLoading={unitsLoading}
                    setState={setState}
                    isResourceForm={isResourceForm}
                    onCreateOwner={onCreateOwner}
                />
            )}
        </FormModal>
    ) : (
        <>
            {isLoading ? (
                <Loading />
            ) : personModalState.open ? (
                <AddProjectOwnerModal
                    personModalState={personModalState}
                    setIsSaveDisabled={setIsSaveDisabled}
                    guestEditorRef={guestEditorRef}
                />
            ) : (
                <ActivityEditorContent
                    state={state}
                    showBasic={showBasic}
                    resourceOptions={resourceOptions}
                    activitiesLoading={activitiesLoading}
                    activityOptions={activityOptions}
                    unitOptions={unitOptions}
                    unitsLoading={unitsLoading}
                    setState={setState}
                    isResourceForm={isResourceForm}
                    onCreateOwner={onCreateOwner}
                />
            )}
        </>
    );
};
