import React from 'react';
import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useStyling } from '../common/Theme';
import EllipsisText from '../common/components/EllipsisText';

type LabelValueDisplayProps = {
    label: string;
    value: string;
    labelOnTop?: boolean;
    labelSize?: number;
    labelSx?: SxProps;
    valueSx?: SxProps;
    onClickValue?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const LabelValueDisplay = ({
    label,
    value,
    labelOnTop = true, // By Default, Label will be displayed on top
    labelSize = 12, // Default size for label in px
    labelSx,
    valueSx,
    onClickValue = () => {}
}: LabelValueDisplayProps) => {
    const { theme } = useStyling();
    const valueSize = Number(labelSize) + 2; // value should always be greater by 2px than Label

    return (
        <div>
            {labelOnTop ? (
                <>
                    <EllipsisText
                        text={label}
                        sx={{ fontSize: `${labelSize}px`, color: theme.palette.text.secondary, ...labelSx }}
                    />
                    <Typography
                        variant={'body2'}
                        sx={{ fontSize: `${valueSize}px`, color: theme.palette.text.primary, ...valueSx }}
                        onClick={onClickValue}
                    >
                        {value}
                    </Typography>
                </>
            ) : (
                <>
                    <Typography
                        variant={'body2'}
                        sx={{ fontSize: `${valueSize}px`, color: theme.palette.text.primary, ...valueSx }}
                        onClick={onClickValue}
                    >
                        {value}
                    </Typography>
                    <EllipsisText
                        text={label}
                        sx={{ fontSize: `${labelSize}px`, color: theme.palette.text.secondary, ...labelSx }}
                    />
                </>
            )}
        </div>
    );
};
