import { Typography } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './common/App';
import Center from './common/components/Center';
import { Icons } from './common/icons/Icons';
import LazyImport from './common/LazyImport';
import { RouteType } from './common/nav/AppRoute';
import { ADMINISTRATION, GUEST, MARKETPLACE, PLANNING, PROFILES, SUPPLIER } from './common/nav/apps';
import { ProductionModes } from './production/ProductionList';

LicenseInfo.setLicenseKey(window._env_.MATERIAL_PRO_KEY);

const supplierRoutes: RouteType[] = [
    {
        label: 'Home',
        app: SUPPLIER,
        feature: 'Home',
        icon: Icons.Home,
        link: `${SUPPLIER.home}`,
        path: `${SUPPLIER.home}`,
        component: LazyImport(() => import('./supply/dashboard/Dashboard')),
        drawer: true
    },
    {
        app: SUPPLIER,
        feature: 'Leads',
        path: `${SUPPLIER.home}/details/:bookingId/resource/:resourceId`,
        component: LazyImport(() => import('./production/resource/ResourceEditor'))
    },
    {
        label: 'Leads',
        app: SUPPLIER,
        icon: Icons.Leads,
        link: `${SUPPLIER.home}/leads`,
        path: `${SUPPLIER.home}/leads`,
        feature: 'Leads',
        component: LazyImport(() => import('./supply/opportunity/leads/Leads')),
        drawer: true
    },
    {
        app: SUPPLIER,
        feature: 'Opportunities',
        path: `${SUPPLIER.home}/details/:bookingId/lineitem/:lineItemId`,
        component: LazyImport(() => import('./supply/opportunity/details/OpportunityDetails'))
    },
    {
        app: SUPPLIER,
        feature: 'Opportunities',
        path: `${SUPPLIER.home}/details/:bookingId`,
        component: LazyImport(() => import('./supply/opportunity/details/OpportunityDetails'))
    },
    {
        app: SUPPLIER,
        feature: 'Opportunities',
        path: `${SUPPLIER.home}/booking/:bookingId`,
        component: LazyImport(() => import('./supply/booking/BookingEditor'))
    },
    {
        label: 'Booking',
        app: SUPPLIER,
        feature: 'Opportunities',
        icon: Icons.Bookings,
        link: `${SUPPLIER.home}/booking`,
        path: `${SUPPLIER.home}/booking`,
        component: LazyImport(() => import('./supply/opportunity/Opportunities')),
        drawer: true
    },
    {
        app: SUPPLIER,
        feature: 'Opportunities',
        path: `${SUPPLIER.home}/new-booking`,
        component: LazyImport(() => import('./supply/booking/BookingEditor'))
    },
    {
        app: SUPPLIER,
        feature: 'Orders',
        path: `${SUPPLIER.home}/:bookingId/order/:reviseOrderId`,
        component: LazyImport(() => import('./supply/opportunity/orders/OrderEditor'))
    },
    {
        app: SUPPLIER,
        feature: 'Quote',
        path: `${SUPPLIER.home}/:bookingId/quote/:quoteId`,
        component: LazyImport(() => import('./supply/opportunity/orders/OrderEditor'))
    },
    {
        app: SUPPLIER,
        feature: 'Quote',
        path: `${SUPPLIER.home}/quote/:id`,
        component: LazyImport(() => import('./supply/opportunity/orders/OrderEditor'))
    },
    {
        app: SUPPLIER,
        feature: 'Orders',
        path: `${SUPPLIER.home}/order/:id`,
        component: LazyImport(() => import('./supply/opportunity/orders/OrderEditor'))
    },
    {
        app: SUPPLIER,
        feature: 'Reservations',
        path: `${SUPPLIER.home}/reservations/:entityId`,
        component: LazyImport(() => import('./supply/reservation/Reservations'))
    },
    {
        label: 'Schedule',
        app: SUPPLIER,
        feature: 'Reservations',
        icon: Icons.Schedule,
        link: `${SUPPLIER.home}/reservations`,
        path: `${SUPPLIER.home}/reservations`,
        component: LazyImport(() => import('./supply/dashboard/Timeline/MultiLocationTimelineContainer')),
        drawer: true
    },
    {
        label: 'Schedule Preview',
        app: SUPPLIER,
        feature: 'Scheduler Preview',
        icon: Icons.SchedulerPreview,
        link: `${SUPPLIER.home}/scheduler-preview`,
        path: `${SUPPLIER.home}/scheduler-preview`,
        component: LazyImport(() => import('./scheduler/TimelinePreviewContainer')),
        drawer: true
    },
    {
        app: SUPPLIER,
        feature: 'Report',
        path: `${SUPPLIER.home}/reports/:reportId`,
        component: LazyImport(() => import('./supply/report/details/ReportDetails'))
    },
    {
        label: 'Reporting',
        app: SUPPLIER,
        feature: 'Report',
        icon: Icons.Report,
        link: `${SUPPLIER.home}/reports`,
        path: `${SUPPLIER.home}/reports`,
        drawer: true,
        component: LazyImport(() => import('./supply/report/Reports'))
    },
    {
        label: 'Test Reporting',
        app: SUPPLIER,
        feature: 'Lab',
        icon: Icons.TestReport,
        link: `${SUPPLIER.home}/testreports`,
        path: `${SUPPLIER.home}/testreports`,
        drawer: true,
        component: LazyImport(() => import('./supply/report/TestReports'))
    },
    {
        app: SUPPLIER,
        feature: 'Catalog',
        path: `${SUPPLIER.home}/catalog/:entityId`,
        component: LazyImport(() => import('./supply/product/Products'))
    },
    {
        app: SUPPLIER,
        feature: 'Catalog',
        path: `${SUPPLIER.home}/catalog-prev/:entityId`,
        component: LazyImport(() => import('./supply/product/Products'))
    },
    {
        app: SUPPLIER,
        feature: 'Catalog',
        path: `${SUPPLIER.home}/catalog/rates/:entityId`,
        component: LazyImport(() => import('./supply/rate/Rates'))
    },
    {
        label: 'Catalog',
        app: SUPPLIER,
        feature: 'Catalog',
        icon: Icons.Catalog,
        path: `${SUPPLIER.home}/catalog`,
        component: LazyImport(() => import('./supply/product/Products')),
        drawer: true,
        items: [
            {
                label: 'Facilities',
                app: SUPPLIER,
                feature: 'Catalog',
                icon: Icons.Facilities,
                link: `${SUPPLIER.home}/catalog`,
                query: { type: 'Facility' },
                drawer: true
            },
            {
                label: 'Services',
                app: SUPPLIER,
                feature: 'Catalog',
                icon: Icons.Services,
                link: `${SUPPLIER.home}/catalog`,
                query: { type: 'Service' },
                drawer: true
            },
            {
                label: 'Equipment',
                app: SUPPLIER,
                feature: 'Catalog',
                icon: Icons.Equipement,
                link: `${SUPPLIER.home}/catalog`,
                query: { type: 'Equipment' },
                drawer: true
            },
            {
                label: 'Products',
                app: SUPPLIER,
                feature: 'Catalog',
                icon: Icons.Products,
                link: `${SUPPLIER.home}/catalog`,
                query: { type: 'Product' },
                drawer: true
            },
            {
                label: 'Crew',
                app: SUPPLIER,
                feature: 'Catalog',
                icon: Icons.Crew,
                link: `${SUPPLIER.home}/catalog`,
                query: { type: 'Crew' },
                drawer: true
            },
            {
                label: 'Rates',
                app: SUPPLIER,
                feature: 'Rates',
                icon: Icons.Rate,
                link: `${SUPPLIER.home}/catalog/rates`,
                drawer: true,
                path: `${SUPPLIER.home}/catalog/rates`,
                query: { rateFor: 'Product' },
                component: LazyImport(() => import('./supply/rate/Rates'))
            }
        ]
    },
    {
        label: 'Catalog (Prev)',
        app: SUPPLIER,
        feature: 'Catalog',
        icon: Icons.CatalogPrev,
        query: { type: undefined },
        link: `${SUPPLIER.home}/catalog-prev`,
        path: `${SUPPLIER.home}/catalog-prev`,
        component: LazyImport(() => import('./supply/product/Products')),
        drawer: true,
        divider: true
    },
    {
        // We don't have details screen in SUPPLIER app, show editor.
        app: SUPPLIER,
        feature: 'Production',
        path: `${SUPPLIER.home}/production/:entityId/details`,
        component: LazyImport(() => import('./production/form/ProductionEditor'))
    },
    {
        app: SUPPLIER,
        feature: 'Production',
        path: `${SUPPLIER.home}/production/:entityId`,
        component: LazyImport(() => import('./production/form/ProductionEditor'))
    },
    {
        label: 'Productions',
        app: SUPPLIER,
        feature: 'Production',
        icon: Icons.Production,
        link: `${SUPPLIER.home}/production`,
        path: `${SUPPLIER.home}/production`,
        redirect: `${SUPPLIER.home}`,
        query: { productionMode: undefined },
        drawer: true,
        component: LazyImport(() => import('./production/ProductionList'))
    },
    {
        label: 'Organizations Editor',
        app: SUPPLIER,
        feature: 'Organization Units',
        path: `${SUPPLIER.home}/organizations/:entityId`,
        component: LazyImport(() => import('./organization/OrganizationEditor'))
    },
    {
        label: 'Organizations',
        app: SUPPLIER,
        feature: 'Organization Units',
        icon: Icons.Organization,
        link: `${SUPPLIER.home}/organizations`,
        path: `${SUPPLIER.home}/organizations`,
        drawer: true,
        component: LazyImport(() => import('./organization/OrganizationList'))
    },
    {
        app: SUPPLIER,
        feature: 'Organization Units',
        path: `${SUPPLIER.home}/organizations/:entityId/details`,
        component: LazyImport(() => import('./organization/detailsPage/OrganizationDetailsPage'))
    },
    {
        label: 'Contact Editor',
        app: SUPPLIER,
        feature: 'People Resources',
        path: `${SUPPLIER.home}/people/:entityId`,
        component: LazyImport(() => import('./person/GuestEditor'))
    },
    {
        label: 'People',
        app: SUPPLIER,
        feature: 'People Resources',
        icon: Icons.People,
        link: `${SUPPLIER.home}/people`,
        path: `${SUPPLIER.home}/people`,
        redirect: `${SUPPLIER.home}`,
        drawer: true,
        divider: true,
        component: LazyImport(() => import('./person/PersonList'))
    },
    {
        app: SUPPLIER,
        feature: 'People Resources',
        path: `${SUPPLIER.home}/people/:entityId/details`,
        component: LazyImport(() => import('./person/detailsPage/PersonDetailsPage'))
    },
    {
        label: 'Lab',
        app: SUPPLIER,
        feature: 'Lab',
        icon: Icons.Lab,
        drawer: true,
        divider: true,
        items: [
            {
                label: 'Forms',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.Forms,
                link: `${SUPPLIER.home}/lab/forms`,
                path: `${SUPPLIER.home}/lab/forms`,
                drawer: true,
                component: LazyImport(() => import('./lab/form/LabForms'))
            },
            {
                label: 'Money',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.Money,
                link: `${SUPPLIER.home}/lab/money`,
                path: `${SUPPLIER.home}/lab/money`,
                drawer: true,
                component: LazyImport(() => import('./lab/money/LabMoney'))
            },
            {
                label: 'Grid',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.Grid,
                link: `${SUPPLIER.home}/lab/grid`,
                path: `${SUPPLIER.home}/lab/grid`,
                drawer: true,
                component: LazyImport(() => import('./lab/grid/LabGrid'))
            },
            {
                label: 'Grid grouping',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.GridGrouping,
                link: `${SUPPLIER.home}/lab/grouping`,
                path: `${SUPPLIER.home}/lab/grouping`,
                drawer: true,
                component: LazyImport(() => import('./lab/grid/LabGroupingGrid'))
            },
            {
                label: 'Filters',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.Filters,
                link: `${SUPPLIER.home}/lab/filters`,
                path: `${SUPPLIER.home}/lab/filters`,
                drawer: true,
                component: LazyImport(() => import('./lab/filters/LabFilters'))
            },
            {
                label: 'Production Header',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.Production,
                link: `${SUPPLIER.home}/lab/production`,
                path: `${SUPPLIER.home}/lab/production`,
                drawer: true,
                component: LazyImport(() => import('./lab/marketplace/LabProduction'))
            },
            {
                label: 'Attributes',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.Attributes,
                link: `${SUPPLIER.home}/lab/attributes`,
                path: `${SUPPLIER.home}/lab/attributes`,
                drawer: true,
                component: LazyImport(() => import('./lab/attributes/LabAttributes'))
            },
            {
                label: 'Attribute Value',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.Attributes,
                link: `${SUPPLIER.home}/lab/attributeValue`,
                path: `${SUPPLIER.home}/lab/attributeValue`,
                drawer: true,
                component: LazyImport(() => import('./lab/attributes/LabAttributeValue'))
            },
            {
                label: 'Rich text',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.RichText,
                link: `${SUPPLIER.home}/lab/richtext`,
                path: `${SUPPLIER.home}/lab/richtext`,
                drawer: true,
                component: LazyImport(() => import('./lab/form/LabRichText'))
            },
            {
                label: 'Images',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.Image,
                link: `${SUPPLIER.home}/lab/images`,
                path: `${SUPPLIER.home}/lab/images`,
                drawer: true,
                component: LazyImport(() => import('./lab/form/LabAttachments'))
            },
            {
                label: 'Avatar',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.AccountCircle,
                link: `${SUPPLIER.home}/lab/avatar`,
                path: `${SUPPLIER.home}/lab/avatar`,
                drawer: true,
                component: LazyImport(() => import('./lab/form/LabAvatar'))
            },
            {
                label: 'Lifecycle',
                app: SUPPLIER,
                feature: 'Lab',
                icon: Icons.Cache,
                link: `${SUPPLIER.home}/lab/lifecycle/01HJK772FNYX8JKP8V3YCFZXEH`,
                path: `${SUPPLIER.home}/lab/lifecycle/:shortlistId`,
                drawer: true,
                component: LazyImport(() => import('./lab/lifecycle/LabModalEdit'))
            }
        ]
    }
];

const profilesRoutes: RouteType[] = [
    {
        label: 'Home',
        app: PROFILES,
        feature: 'Home',
        icon: Icons.Home,
        link: `${PROFILES.home}`,
        path: `${PROFILES.home}`,
        drawer: true,
        component: LazyImport(() => import('./profile/home/Home'))
    },
    {
        label: 'Profile',
        app: PROFILES,
        feature: 'Account',
        icon: Icons.Profile,
        link: `${PROFILES.home}/profile`,
        path: `${PROFILES.home}/profile`,
        drawer: true,
        component: LazyImport(() => import('./person/detailsPage/PersonDetailsPage'))
    },
    {
        label: 'Leads',
        app: PROFILES,
        icon: Icons.Leads,
        link: `${PROFILES.home}/leads`,
        path: `${PROFILES.home}/leads`,
        feature: 'Leads',
        component: LazyImport(() => import('./supply/opportunity/leads/Leads')),
        drawer: true
    },
    {
        app: PROFILES,
        feature: 'Leads',
        path: `${PROFILES.home}/details/:bookingId`,
        component: LazyImport(() => import('./supply/opportunity/details/OpportunityDetails'))
    },
    {
        app: PROFILES,
        feature: 'Organization Units',
        path: `${PROFILES.home}/organizationunits/:entityId/details`,
        component: LazyImport(() => import('./organization/detailsPage/OrganizationDetailsPage'))
    },
    {
        label: 'Organizations Editor',
        app: PROFILES,
        feature: 'Organization Units',
        path: `${PROFILES.home}/organizationunits/:entityId`,
        component: LazyImport(() => import('./organization/OrganizationEditor'))
    },
    {
        label: 'Booking',
        app: PROFILES,
        feature: 'Bookings',
        icon: Icons.Bookings,
        link: `${PROFILES.home}/booking`,
        path: `${PROFILES.home}/booking`,
        component: LazyImport(() => import('./supply/opportunity/Opportunities')),
        drawer: true,
        divider: true
    },
    {
        label: 'Schedule Preview',
        app: PROFILES,
        feature: 'Scheduler Preview',
        icon: Icons.SchedulerPreview,
        link: `${PROFILES.home}/scheduler-preview`,
        path: `${PROFILES.home}/scheduler-preview`,
        component: LazyImport(() => import('./supply/dashboard/Timeline/MultiLocationTimelineContainer')),
        drawer: true
    },
    {
        label: 'Shortlists',
        app: PROFILES,
        feature: 'Shortlist',
        icon: Icons.Shortlist,
        link: `${PROFILES.home}/shortlists`,
        path: `${PROFILES.home}/shortlists`,
        drawer: true,
        component: LazyImport(() => import('./shortlists/Shortlists'))
    },
    {
        label: 'Organizations',
        app: PROFILES,
        feature: 'Organization Units',
        icon: Icons.Organization,
        path: `${PROFILES.home}/organizationunits`,
        link: `${PROFILES.home}/organizationunits`,
        component: LazyImport(() => import('./organization/OrganizationList')),
        drawer: true
    },
    {
        label: 'People',
        app: PROFILES,
        feature: 'People Resources',
        icon: Icons.People,
        path: `${PROFILES.home}/people`,
        link: `${PROFILES.home}/people`,
        component: LazyImport(() => import('./person/PersonList')),
        drawer: true
    },
    {
        app: PROFILES,
        feature: 'People Resources',
        path: `${PROFILES.home}/people/:entityId/details`,
        component: LazyImport(() => import('./person/detailsPage/PersonDetailsPage'))
    },
    {
        label: 'Productions',
        app: PROFILES,
        feature: 'Production',
        icon: Icons.Production,
        link: `${PROFILES.home}/production`,
        path: `${PROFILES.home}/production`,
        query: { productionMode: ProductionModes.production },
        drawer: true,
        component: LazyImport(() => import('./production/ProductionList'))
    },
    {
        app: PROFILES,
        feature: 'Production',
        path: `${PROFILES.home}/production/:entityId`,
        component: LazyImport(() => import('./production/form/ProductionEditor'))
    },
    {
        app: PROFILES,
        feature: 'Production',
        path: `${PROFILES.home}/production/:entityId/details`,
        component: LazyImport(() => import('./production/form/ProductionEditor'))
    },
    {
        app: PROFILES,
        feature: 'Production',
        path: `${PROFILES.home}/production/:productionId/details`,
        query: { productionMode: ProductionModes.production },
        component: LazyImport(() => import('./production/ProductionDetails'))
    },

    {
        label: 'Catalog',
        app: PROFILES,
        feature: 'Catalog',
        icon: Icons.Catalog,
        path: `${PROFILES.home}/catalog`,
        component: LazyImport(() => import('./supply/product/Products')),
        drawer: true,
        items: [
            {
                label: 'Facilities',
                app: PROFILES,
                feature: 'Catalog',
                icon: Icons.Facilities,
                link: `${PROFILES.home}/catalog`,
                query: { type: 'Facility' },
                drawer: true
            },
            {
                label: 'Services',
                app: PROFILES,
                feature: 'Catalog',
                icon: Icons.Services,
                link: `${PROFILES.home}/catalog`,
                query: { type: 'Service' },
                drawer: true
            },
            {
                label: 'Equipment',
                app: PROFILES,
                feature: 'Catalog',
                icon: Icons.Equipement,
                link: `${PROFILES.home}/catalog`,
                query: { type: 'Equipment' },
                drawer: true
            },
            {
                label: 'Products',
                app: PROFILES,
                feature: 'Catalog',
                icon: Icons.Products,
                link: `${PROFILES.home}/catalog`,
                query: { type: 'Product' },
                drawer: true
            },
            {
                label: 'Crew',
                app: PROFILES,
                feature: 'Catalog',
                icon: Icons.Crew,
                link: `${PROFILES.home}/catalog`,
                query: { type: 'Crew' },
                drawer: true
            },
            {
                label: 'Rates',
                app: PROFILES,
                feature: 'Rates',
                icon: Icons.Rate,
                link: `${PROFILES.home}/catalog/rates`,
                drawer: true,
                path: `${PROFILES.home}/catalog/rates`,
                query: { rateFor: 'Product' },
                component: LazyImport(() => import('./supply/rate/Rates'))
            }
        ]
    },
    {
        label: 'Settings',
        app: PROFILES,
        feature: 'Account',
        icon: Icons.Settings,
        link: `${PROFILES.home}/settings`,
        path: `${PROFILES.home}/settings`,
        drawer: true,
        component: () => (
            <Center>
                <Typography fontSize={20}>Personal account settings</Typography>
            </Center>
        )
    }
];

const adminRoutes: RouteType[] = [
    {
        feature: 'Account',
        app: ADMINISTRATION,
        path: `${ADMINISTRATION.home}`,
        component: LazyImport(() => import('./admin/AdminHome'))
    },
    {
        label: 'Directory',
        app: ADMINISTRATION,
        icon: Icons.Directory,
        drawer: true,
        items: [
            {
                label: 'Organizations Editor',
                app: ADMINISTRATION,
                feature: 'Organization Units',
                path: `${ADMINISTRATION.home}/organizationunits/:entityId`,
                component: LazyImport(() => import('./organization/OrganizationEditor'))
            },
            {
                app: ADMINISTRATION,
                feature: 'Organization Units',
                path: `${ADMINISTRATION.home}/organizationunits/:entityId/details`,
                component: LazyImport(() => import('./organization/detailsPage/OrganizationDetailsPage'))
            },
            {
                label: 'Organizations',
                app: ADMINISTRATION,
                feature: 'Organization Units',
                icon: Icons.Organization,
                path: `${ADMINISTRATION.home}/organizationunits`,
                link: `${ADMINISTRATION.home}/organizationunits`,
                component: LazyImport(() => import('./organization/OrganizationList')),
                drawer: true
            },
            {
                app: ADMINISTRATION,
                feature: 'People Resources',
                path: `${ADMINISTRATION.home}/peopleresources/:entityId`,
                component: LazyImport(() => import('./person/PersonEditor'))
            },
            {
                label: 'People',
                app: ADMINISTRATION,
                feature: 'People Resources',
                icon: Icons.People,
                path: `${ADMINISTRATION.home}/peopleresources`,
                link: `${ADMINISTRATION.home}/peopleresources`,
                component: LazyImport(() => import('./person/PersonList')),
                drawer: true
            },
            {
                app: ADMINISTRATION,
                feature: 'People Resources',
                path: `${ADMINISTRATION.home}/peopleresources/:entityId/details`,
                component: LazyImport(() => import('./person/detailsPage/PersonDetailsPage'))
            }
        ]
    },
    {
        label: 'Manage Accounts',
        app: ADMINISTRATION,
        icon: Icons.ManageAccounts,
        drawer: true,
        items: [
            {
                app: ADMINISTRATION,
                feature: 'Organizations',
                path: `${ADMINISTRATION.home}/organizations/:entityId`,
                component: LazyImport(() => import('./admin/organization/OrganizationSettings'))
            },
            {
                label: 'Organizations',
                app: ADMINISTRATION,
                feature: 'Organizations',
                icon: Icons.Organization,
                path: `${ADMINISTRATION.home}/organizations?`,
                link: `${ADMINISTRATION.home}/organizations`,
                component: LazyImport(() => import('./admin/account/Organizations')),
                drawer: true
            },
            {
                app: ADMINISTRATION,
                feature: 'People',
                path: `${ADMINISTRATION.home}/people/:entityId`,
                component: LazyImport(() => import('./admin/account/Users'))
            },
            {
                label: 'People',
                app: ADMINISTRATION,
                feature: 'People',
                icon: Icons.People,
                path: `${ADMINISTRATION.home}/people`,
                link: `${ADMINISTRATION.home}/people`,
                component: LazyImport(() => import('./admin/account/Users')),
                drawer: true
            }
        ]
    },
    {
        label: 'Account',
        app: ADMINISTRATION,
        icon: Icons.Account,
        drawer: true,
        items: [
            {
                label: 'Profile',
                app: ADMINISTRATION,
                feature: 'Account',
                icon: Icons.Profile,
                link: `${ADMINISTRATION.home}/account/profile`,
                path: `${ADMINISTRATION.home}/account/profile`,
                component: LazyImport(() => import('./admin/organization/OrganizationProfile')),
                drawer: true
            },
            {
                label: 'Settings',
                app: ADMINISTRATION,
                feature: 'Account',
                icon: Icons.Settings,
                link: `${ADMINISTRATION.home}/account/settings`,
                path: `${ADMINISTRATION.home}/account/settings`,
                component: LazyImport(() => import('./admin/organization/OrganizationSettings')),
                drawer: true
            }
        ]
    },
    {
        label: 'Applications Settings',
        app: ADMINISTRATION,
        icon: Icons.ApplicationsSettings,

        drawer: true,
        items: [
            {
                label: 'Fields',
                app: ADMINISTRATION,
                feature: 'Application Settings',
                link: `${ADMINISTRATION.home}/fields`,
                path: `${ADMINISTRATION.home}/fields`,
                drawer: true,
                component: LazyImport(() => import('./supply/termset/Termsets'))
            },
            {
                app: ADMINISTRATION,
                feature: 'Fields',
                path: `${ADMINISTRATION.home}/fields/:fieldId`,
                component: LazyImport(() => import('./supply/termset/TermsetEditor'))
            },
            {
                label: 'Currency',
                app: ADMINISTRATION,
                feature: 'Exchange Rate',
                link: `${ADMINISTRATION.home}/conversion`,
                path: `${ADMINISTRATION.home}/conversion`,
                drawer: true,
                component: LazyImport(() => import('./supply/currency/ConversionList'))
            },
            {
                app: ADMINISTRATION,
                feature: 'Exchange Rate',
                path: `${ADMINISTRATION.home}/conversion/:entityId`,
                component: LazyImport(() => import('./supply/currency/ConversionList'))
            },
            {
                label: 'Quotes',
                app: ADMINISTRATION,
                feature: 'Quote',
                link: `${ADMINISTRATION.home}/quoteSettings`,
                path: `${ADMINISTRATION.home}/quoteSettings`,
                drawer: true,
                component: LazyImport(() => import('./admin/quotes/QuoteSettings'))
            }
        ]
    },
    {
        label: 'Templates',
        app: ADMINISTRATION,
        drawer: true,
        icon: Icons.Templates,
        items: [
            {
                label: 'Resources',
                feature: 'Resources',
                drawer: true,
                app: ADMINISTRATION,
                link: `${ADMINISTRATION.home}/templates/resources`,
                path: `${ADMINISTRATION.home}/templates/resources`,
                component: LazyImport(() => import('./admin/templates/resources/ResourceTemplatesList'))
            }
        ]
    },
    {
        app: ADMINISTRATION,
        feature: 'Resources',
        path: `${ADMINISTRATION.home}/resource/:resourceId`,
        component: LazyImport(() => import('./production/resource/ResourceEditor'))
    },
    {
        app: ADMINISTRATION,
        feature: 'Resources',
        path: `${ADMINISTRATION.home}/templates/resources/:resourceId/details`,
        component: LazyImport(() => import('./admin/templates/resources/ResourceTemplateDetails'))
    },
    {
        label: 'Security',
        app: ADMINISTRATION,
        icon: Icons.Security,
        drawer: true,
        items: [
            {
                label: 'Authentication',
                app: ADMINISTRATION,
                feature: 'Security',
                link: `${ADMINISTRATION.home}/security/authentication`,
                path: `${ADMINISTRATION.home}/security/authentication`,
                component: LazyImport(() => import('./admin/organization/OrganizationSecurityAuth')),
                drawer: true
            },
            {
                label: 'Domains',
                app: ADMINISTRATION,
                feature: 'Security',
                link: `${ADMINISTRATION.home}/security/domains`,
                path: `${ADMINISTRATION.home}/security/domains`,
                component: LazyImport(() => import('./admin/organization/OrganizationDomains')),
                drawer: true
            }
        ]
    },
    {
        label: 'Resource Fields',
        feature: 'Fields',
        drawer: true,
        icon: Icons.ResourceFields,
        app: ADMINISTRATION,
        link: `${ADMINISTRATION.home}/resourceFields`,
        path: `${ADMINISTRATION.home}/resourceFields`,
        component: LazyImport(() => import('./admin/resourceFields/ResourceFields'))
    }
];

const marketplaceRoutes: RouteType[] = [
    {
        label: 'Home',
        feature: 'Home',
        app: MARKETPLACE,
        icon: Icons.Home,
        link: `${MARKETPLACE.home}`,
        path: `${MARKETPLACE.home}`,
        component: LazyImport(() => import('./marketplace/MarketplaceHome'))
    },
    {
        app: MARKETPLACE,
        feature: 'Marketplace',
        path: `${MARKETPLACE.home}/marketplace/:productId`,
        component: LazyImport(() => import('./marketplace/MarketplaceDetails'))
    },
    {
        label: 'Marketplace',
        app: MARKETPLACE,
        feature: 'Marketplace',
        icon: Icons.Marketplace,
        link: `${MARKETPLACE.home}/marketplace`,
        path: `${MARKETPLACE.home}/marketplace`,
        drawer: true,
        component: LazyImport(() => import('./marketplace/MarketplaceList'))
    }
];

const planningRoutes: RouteType[] = [
    {
        label: 'Home',
        feature: 'Production',
        app: PLANNING,
        icon: Icons.Workspaces,
        link: `${PLANNING.home}`,
        path: `${PLANNING.home}`,
        component: LazyImport(() => import('./production/ProductionList'))
    },
    {
        label: 'Shortlists',
        app: PLANNING,
        feature: 'Shortlist',
        icon: Icons.Shortlist,
        link: `${PLANNING.home}/workspace/:productionId/resource/:resourceId/details`,
        path: `${PLANNING.home}/workspace/:productionId/resource/:resourceId/details`,
        component: LazyImport(() => import('./production/resource/ProductionResourceDetails'))
    },
    {
        app: PLANNING,
        feature: 'Production',
        path: `${PLANNING.home}/workspace/:productionId/resource/:resourceId`,
        query: { productionMode: ProductionModes.workspace },
        component: LazyImport(() => import('./production/resource/ResourceEditor'))
    },

    {
        app: PLANNING,
        feature: 'Production',
        path: `${PLANNING.home}/workspace/:productionId`,
        query: { productionMode: ProductionModes.workspace },
        component: LazyImport(() => import('./production/form/CreateProduction'))
    },
    {
        app: PLANNING,
        feature: 'Production',
        path: `${PLANNING.home}/workspace/:productionId/details`,
        query: { productionMode: ProductionModes.workspace },
        component: LazyImport(() => import('./production/ProductionDetails'))
    },
    {
        label: 'Workspace',
        app: PLANNING,
        feature: 'Production',
        icon: Icons.Workspaces,
        link: `${PLANNING.home}/workspace`,
        path: `${PLANNING.home}/workspace`,
        component: LazyImport(() => import('./production/ProductionList')),
        query: { productionMode: ProductionModes.workspace },
        drawer: true
    },
    {
        label: 'Schedule',
        app: PLANNING,
        feature: 'Schedule',
        icon: Icons.Schedule,
        link: `${PLANNING.home}/scheduler`,
        path: `${PLANNING.home}/scheduler`,
        component: LazyImport(() => import('./production/schedulerTimeline/CrossProductionTimelineView')),
        drawer: true
    },
    {
        label: 'Shortlists',
        app: PLANNING,
        feature: 'Shortlist',
        icon: Icons.Shortlist,
        link: `${PLANNING.home}/shortlists`,
        path: `${PLANNING.home}/shortlists`,
        drawer: true,
        component: LazyImport(() => import('./shortlists/Shortlists'))
    },
    {
        label: 'Shortlists',
        app: PLANNING,
        feature: 'Shortlist',
        icon: Icons.Shortlist,
        link: `${PLANNING.home}/shortlists/:shortlistId/details`,
        path: `${PLANNING.home}/shortlists/:shortlistId/details`,
        component: LazyImport(() => import('./shortlists/ShortlistDetailsView'))
    },
    {
        app: PLANNING,
        feature: 'Bookings',
        path: `${PLANNING.home}/details/:bookingId`,
        component: LazyImport(() => import('./supply/opportunity/details/OpportunityDetails'))
    },
    {
        label: 'Booking',
        app: PLANNING,
        feature: 'Bookings',
        icon: Icons.Bookings,
        link: `${PLANNING.home}/booking`,
        path: `${PLANNING.home}/booking`,
        component: LazyImport(() => import('./supply/opportunity/Opportunities')),
        drawer: true,
        divider: true
    },
    {
        app: PLANNING,
        feature: 'Production',
        path: `${PLANNING.home}/production/:entityId/details`,
        component: LazyImport(() => import('./production/form/ProductionEditor'))
    },
    {
        app: PLANNING,
        feature: 'Production',
        path: `${PLANNING.home}/production/:entityId`,
        component: LazyImport(() => import('./production/form/ProductionEditor'))
    },
    {
        label: 'Productions',
        app: PLANNING,
        feature: 'Production',
        icon: Icons.Production,
        link: `${PLANNING.home}/production`,
        path: `${PLANNING.home}/production`,
        query: { productionMode: ProductionModes.production },
        drawer: true,
        component: LazyImport(() => import('./production/ProductionList'))
    },
    {
        label: 'Organizations',
        app: PLANNING,
        feature: 'Organization Units',
        icon: Icons.Organization,
        path: `${PLANNING.home}/organizations`,
        link: `${PLANNING.home}/organizations`,
        component: LazyImport(() => import('./organization/OrganizationList')),
        drawer: true
    },
    {
        label: 'Organizations Editor',
        app: PLANNING,
        feature: 'Organization Units',
        path: `${PLANNING.home}/organizations/:entityId`,
        component: LazyImport(() => import('./organization/OrganizationEditor'))
    },
    {
        app: PLANNING,
        feature: 'Organization Units',
        path: `${PLANNING.home}/organizations/:entityId/details`,
        component: LazyImport(() => import('./organization/detailsPage/OrganizationDetailsPage'))
    },
    {
        label: 'People',
        app: PLANNING,
        feature: 'People Resources',
        icon: Icons.People,
        path: `${PLANNING.home}/people`,
        link: `${PLANNING.home}/people`,
        component: LazyImport(() => import('./person/PersonList')),
        drawer: true
    },
    {
        app: PLANNING,
        feature: 'People Resources',
        path: `${PLANNING.home}/people/:entityId`,
        component: LazyImport(() => import('./person/PersonEditor'))
    },
    {
        app: PLANNING,
        feature: 'People Resources',
        path: `${PLANNING.home}/people/:entityId/details`,
        component: LazyImport(() => import('./person/detailsPage/PersonDetailsPage'))
    }
];

const guestRoutes: RouteType[] = [
    {
        label: 'Leads',
        app: GUEST,
        feature: 'Leads',
        icon: Icons.Leads,
        link: `${GUEST.home}`,
        path: `${GUEST.home}`,
        component: LazyImport(() => import('./supply/opportunity/leads/Leads')),
        drawer: true
    }
];
const root = createRoot(document.getElementById('app'));
root.render(
    <App
        routes={[]
            .concat(supplierRoutes)
            .concat(profilesRoutes)
            .concat(adminRoutes)
            .concat(marketplaceRoutes)
            .concat(planningRoutes)
            .concat(guestRoutes)}
    />
);
