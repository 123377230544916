import { SvgIcon } from '@mui/material';
import React, { CSSProperties, useMemo } from 'react';
import { Icons } from '../icons/Icons';
import { H } from '../Layout';
import { useStyling } from '../Theme';
import useLoadAttachment from './useLoadAttachment';

type SecureImageProps = {
    imageId: string;
    alt?: string;
    style?: CSSProperties;
};

export default ({ imageId, style = {}, alt = 'Logo' }: SecureImageProps) => {
    const { theme } = useStyling();
    const { attachment, free } = useLoadAttachment(imageId);

    return useMemo(() => {
        return attachment ? (
            <img className="_secureImage" alt={alt} style={style} src={attachment} onLoad={() => free()} />
        ) : (
            <H
                fill
                sx={{
                    justifyContent: 'center',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1
                }}
            >
                <SvgIcon
                    component={Icons.BrokenImage}
                    sx={{ color: `${theme.palette.text.secondary}` }}
                    fontSize="large"
                />
            </H>
        );
    }, [attachment, style]);
};
