import React, { useEffect, useMemo } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { getLocation } from '../../common/components/location';


export default({row}) => {

    const locationField = 'availableLocations';
    const importedField = 'location';
    const searchText = row[importedField];
    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
        apiKey: window._env_.GOOGLE_MAPS_LOCATION_API_KEY,
        debounce: 500
    });

    useEffect(() => {
        if (searchText) {
            getPlacePredictions({ input: searchText, types: [] });
        }
    }, [searchText]);

   useEffect(() => {
        if (placePredictions && placePredictions.length) {
            const place = placePredictions[0];
            placesService?.getDetails(
                {
                    placeId: place.place_id
                },
                (placeDetails) => {
                    getLocation(placeDetails, true).then((data) => {
                        let location = data;
                        location.label = place.description;
                        location.primary = true;
                        row[locationField] = [location];
                    });
                }
            );
        }
    }, [placePredictions]);

    return (<React.Fragment/>);
};
