import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useStyling } from '../../common/Theme';

export default ({
    text,
    width = 100,
    color = undefined
}: {
    text: string | string[];
    width?: number;
    color?: string;
}) => {
    const { theme } = useStyling();
    const content = Array.isArray(text) ? text.join(', ') : text;

    return (
        <Tooltip title={<Typography fontSize="small">{content}</Typography>}>
            <Typography maxWidth={width} className="ellipsis" color={color ? color : theme.palette.text.primary}>
                {content}
            </Typography>
        </Tooltip>
    );
};
