export const COLOR_PRIMARY_LIGHT = 'rgba(0,17,168,1)';
export const COLOR_PRIMARY_DARK = 'rgba(33,150,243,1)';
export const COLOR_ERROR_LIGHT = 'rgba(193,63,56,1)';
export const COLOR_ERROR_DARK = 'rgba(193,63,56,1)';
export const COLOR_SUCCESS_DARK = 'rgba(80,139,70,1)';
export const COLOR_SUCCESS_LIGHT = 'rgba(80,139,70,1)';
export const COLOR_WARNING_DARK = 'rgba(241,157,56,1)';
export const COLOR_WARNING_LIGHT = 'rgba(241,157,56,1)';
export const COLOR_EDITING_DARK = 'rgba(69, 90, 100, 1)';
export const COLOR_EDITING_LIGHT = 'rgba(207, 216, 220, 1)';
export const COLOR_FOCUS_DARK = 'rgba(144, 202, 249, 0.12)';
export const COLOR_FOCUS_LIGHT = 'rgba(0, 17, 168, 0.12)';
