import { isNil } from 'lodash';
import React from 'react';
import FormModal from '../../common/form/FormModal';
import I18n from '../../common/i18n/I18n';
import { OrganizationInviteFormModalProps } from './inquiry/inquiry';
import InquiryContactDetailsForm from './InquiryContactDetailsForm';
import OrganizationInviteFormItems from './OrganizationInviteFormItems';

export default ({
    isOpen,
    onClose,
    onSave,
    isLoading,
    isSaving,
    isEmailModalOpen,
    itemsWithNoEmail,
    data
}: OrganizationInviteFormModalProps) => {
    return (
        <FormModal
            id={'org-inquiry'}
            title={<I18n token={'organization.inquiry.form.label'} />}
            isOpen={isOpen}
            onClose={onClose}
            onSave={onSave}
            isLoading={isLoading}
            isSaving={isSaving}
            saveLabel={
                <I18n
                    token={
                        !isEmailModalOpen
                            ? 'organization.inquiry.form.send'
                            : 'organization.add.contact.button.continue'
                    }
                />
            }
        >
            {isNil(isEmailModalOpen) ? (
                <></>
            ) : isEmailModalOpen ? (
                <InquiryContactDetailsForm name={'organizations'} itemsWithNoContact={itemsWithNoEmail} />
            ) : (
                <OrganizationInviteFormItems data={data} />
            )}
        </FormModal>
    );
};
